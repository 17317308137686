import { FC, useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import LifeList from 'shared/ui/LifeList/LifeList';
import Modal from 'shared/ui/Modal/Modal';
import { useWebSocket, webSocketMessages } from '../../shared/context/WebSocketContext';
import { useGame } from '../../shared/context/GameContext';
import {
  antalyaSVG,
  dubaiSVG,
  ekatSVG,
  habarovskSVG,
  hurgadaSVG,
  kaliningradSVG,
  kazanSVG,
  maldivesSVG,
  nnSVG,
  pekinSVG,
  phuketSVG,
  sanktPeterburgSVG,
  sanyaSVG,
  seychellesSVG,
  sochiSVG,
  ufaSVG,
  varaderoSVG,
  vladivostokSVG,
  volgogradSVG,
  yerevanSVG,
} from '../../shared/resourcesBase64/images/cities';
import { finalDesktopBackSVG, finalPhoneBackSVG } from '../../shared/resourcesBase64/images/backs';
import './index.scss';

const CitiesList = {
  antalya: {
    svg: antalyaSVG,
    alt: 'Анталия',
  },
  dubai: {
    svg: dubaiSVG,
    alt: 'Дубай',
  },
  ekat: {
    svg: ekatSVG,
    alt: 'Екатеринбург',
  },
  habarovsk: {
    svg: habarovskSVG,
    alt: 'Хабаровск',
  },
  hurgada: {
    svg: hurgadaSVG,
    alt: 'Хургада',
  },
  kaliningrad: {
    svg: kaliningradSVG,
    alt: 'Калининград',
  },
  kazan: {
    svg: kazanSVG,
    alt: 'Казань',
  },
  maldives: {
    svg: maldivesSVG,
    alt: 'Мальдивы',
  },
  nn: {
    svg: nnSVG,
    alt: 'Нижний Новгород',
  },
  pekin: {
    svg: pekinSVG,
    alt: 'Пекин',
  },
  phuket: {
    svg: phuketSVG,
    alt: 'Пхукет',
  },
  sanktPeterburg: {
    svg: sanktPeterburgSVG,
    alt: 'Санкт-Петергбург',
  },
  sanya: {
    svg: sanyaSVG,
    alt: 'Санья',
  },
  seychelles: {
    svg: seychellesSVG,
    alt: 'Сейшелы',
  },
  sochi: {
    svg: sochiSVG,
    alt: 'Сочи',
  },
  ufa: {
    svg: ufaSVG,
    alt: 'Уфа',
  },
  varadero: {
    svg: varaderoSVG,
    alt: 'Варадеро',
  },
  vladivostok: {
    svg: vladivostokSVG,
    alt: 'Владивосток',
  },
  volgograd: {
    svg: volgogradSVG,
    alt: 'Волгоград',
  },
  yerevan: {
    svg: yerevanSVG,
    alt: 'Ереван',
  },
};

const Final: FC = () => {
  const [isPhone, setIsPhone] = useState(window.matchMedia('(max-width: 480px)').matches);
  const { score, promoCodes = [] } = useGame();

  const { lastMessage, sendMessage } = useWebSocket();
  const navigate = useNavigate();

  const finalRef = useRef<HTMLDivElement>(null);

  const [lives, setLives] = useState(0);

  const [isOpen, setIsOpen] = useState(true);
  const [modalNode, setModalNode] = useState<HTMLDivElement | null>(null);

  const [mounted, setMounted] = useState(false);

  const citiesKeys = Object.keys(CitiesList);
  const getRandomCity = (arr: string[]) => arr[Math.floor(Math.random() * arr.length - 1)];

  const keyLeftRef = useRef(getRandomCity(citiesKeys));
  const keyRightRef = useRef(getRandomCity(citiesKeys));

  while (keyRightRef.current === keyLeftRef.current || !keyRightRef.current) {
    keyRightRef.current = getRandomCity(citiesKeys);
  }

  useEffect(() => {
    window
      .matchMedia('(max-width: 480px)')
      .addEventListener('change', (e) => setIsPhone(e.matches));
  }, []);

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .layout-final .content {
        background-image: url('${isPhone ? finalPhoneBackSVG : finalDesktopBackSVG}');
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, [isPhone]);

  useEffect(() => {
    if (!(keyLeftRef.current && keyRightRef.current)) return;

    setMounted(true);
  }, [keyLeftRef, keyRightRef]);

  useEffect(() => {
    setModalNode(finalRef.current);
  }, []);

  useEffect(() => {
    if (!lastMessage) return;
    const { life_count, score: newScore } = lastMessage.message || {};
    console.log('life_count ', life_count);

    // setLives(life_count);
    // setMessageHistory((prev) => prev.concat(message));
  }, [lastMessage]);

  const ModalTextData = {
    1: {
      title: `Андрей успел на рейс, 
а вы набрали ${score} баллов!`,
      text: 'Следите за своим рейтингом в турнирной таблице в личном кабинете игры. Чем выше рейтинг, тем вы ближе к победе. Сыграйте еще раз, чтобы набрать еще больше баллов, или запланируйте новое путешествие',
    },
    2: {
      title: `Андрей успел на рейс, 
а вы набрали ${score} баллов и получили промокоды ${promoCodes?.length > 1 ? promoCodes.join(', ') : promoCodes ? promoCodes[0] : ''}!`,
      text: 'Посмотреть свои промокоды и рейтинг в турнирной таблице можно в личном кабинете игры. Чем выше рейтинг, тем вы ближе к победе. Сыграйте еще раз, чтобы набрать еще больше баллов, или запланируйте новое путешествие',
    },
  };

  const handlePlayAgain = () => {
    sendMessage({ action: webSocketMessages.NEW_GAME, value: '' });
    navigate('/rules');
  };

  return (
    <div className="container final" ref={finalRef}>
      <div className="game-info">
        <div className="info-score">{score}</div>
        <div className="info-lives">
          <LifeList activeLivesAmount={lives} />
        </div>
      </div>
      {mounted && (
        <Modal
          isOpen={isOpen}
          onClose={() => setIsOpen(false)}
          cls="modal-final"
          container={modalNode}
        >
          <Title type={'h2'} text={ModalTextData[promoCodes?.length ? 2 : 1].title} />
          <Text size="default" text={ModalTextData[promoCodes?.length ? 2 : 1].text} />
          <div className="cities">
            <img
              src={CitiesList[keyLeftRef.current as keyof typeof CitiesList].svg}
              alt={CitiesList[keyLeftRef.current as keyof typeof CitiesList].alt}
            />
            <img
              src={CitiesList[keyRightRef.current as keyof typeof CitiesList].svg}
              alt={CitiesList[keyRightRef.current as keyof typeof CitiesList].alt}
            />
          </div>
          <Button
            size="medium"
            label="Играть еще раз"
            classNames={['final-button']}
            onClick={handlePlayAgain}
          />
        </Modal>
      )}
    </div>
  );
};

export default Final;
