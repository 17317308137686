import React, { FC } from 'react';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useLoginMutation } from '../../shared/redux/services/authApi';
import { useDispatch } from 'react-redux';
import { setTokens } from '../../shared/redux/store/auth/authSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { aeroflotUrlList, userData } from '../../shared/config/config';
import { openNewSafeWindow } from '../../shared/tools/openNewSafeWindow';

const Navigation: FC = () => {
  //hook generated by RTK query createAPI. login is the method to call endpoint
  const [login, { isLoading }] = useLoginMutation();
  //dispatcher for authSlice reducer
  const dispatch: Dispatch<UnknownAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const handleSignIn: () => void = () => {
    openNewSafeWindow(aeroflotUrlList.signIn);

    // Make a login endapoint call. Unwraps a mutation call to provide the raw response/error
    // const userData = await login({ email, password }).unwrap();
    // if (isLoading) return;
    //
    // await login({ username: userData.username, password: userData.password })
    //   .unwrap()
    //   .then((userData: any) => {
    //     // Set credentials in redux store
    //     const { refresh: refresh_token, access: access_token } = userData;
    //     dispatch(setTokens({ refresh_token, access_token }));
    //     // redirect user to registration page
    //     navigate('/registration');
    //   })
    //   .catch((err) => {
    //     console.log('err ', err);
    //     alert('Invalid username or password');
    //   });
  };

  const handleSignUp: () => void = async () => {
    // TODO: uncomment this after redirect link from Aeroflot.bonus after signIn is working
    // openNewSafeWindow(aeroflotUrlList.signUp);

    // Make a login endapoint call. Unwraps a mutation call to provide the raw response/error
    // const userData = await login({ email, password }).unwrap();
    if (isLoading) return;

    await login({ username: userData.username, password: userData.password })
      .unwrap()
      .then((userData: any) => {
        // Set credentials in redux store
        const { refresh: refresh_token, access: access_token } = userData;
        dispatch(setTokens({ refresh_token, access_token }));
        // redirect user to registration page
        navigate('/registration');
      })
      .catch((err) => {
        console.log('err ', err);
        alert('Invalid username or password');
      });
  };

  return (
    <div className="navigation">
      <Title type={'h1'} text="В погоне за милями" />
      <Text
        classNames={['navigation-text']}
        text="Помогите Андрею успеть на рейс, наберите как можно больше баллов и станьте победителем"
      />
      <Button onClick={handleSignIn} classNames={['navigation-button']} label="Войти в игру" />
      <Text
        classNames={['navigation-text']}
        size="small"
        text="Если вы участник программы «Аэрофлот Бонус», нажмите на кнопку"
      />
      <Button
        onClick={handleSignUp}
        classNames={['navigation-button']}
        label="Зарегистрироваться"
      />
      <Text
        size="small"
        text="Если вы еще не зарегистрировались в программе «Аэрофлот Бонус», пройдите регистрацию и возвращайтесь в игру"
      />
    </div>
  );
};

export default Navigation;
