import { memo } from 'react';
import { lifeActiveSVG, lifeInactiveSVG } from '../../resourcesBase64/images/icons';
import './LifeList.scss';

type lifeListProps = {
  activeLivesAmount: number;
};
const LifeList = memo(({ activeLivesAmount = 0 }: lifeListProps) =>
  [...Array(3)].map((life, index) => (
    <img
      key={index}
      className="life-image"
      src={activeLivesAmount - index > 0 ? lifeActiveSVG : lifeInactiveSVG}
      alt={`жизнь ${activeLivesAmount - index > 0 ? 'в запасе' : 'сгорела'}`}
    />
  ))
);

export default LifeList;
