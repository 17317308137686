import { ButtonHTMLAttributes } from 'react';
import './Button.scss';

interface ButtonProps extends ButtonHTMLAttributes<HTMLButtonElement> {
  /**
   * @description Обводка у кнопки
   */
  isOutlined?: boolean;
  /**
   * @description Состояние нажато
   */
  isPressed?: boolean;
  /**
   * @description Состояние выключено
   */
  isDisabled?: boolean;
  /**
   * @description Текст кнопки
   */
  label?: string;
  /**
   * @description Размер кнопки
   */
  size?: 'medium' | 'small';
  /**
   * @description Массив из классов
   */
  classNames?: Array<string>;
  /**
   * @description Обработчик нажатия на кнопку
   */
  // eslint-disable-next-line no-unused-vars
  onClick?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
  /**
   * @description JSX элемент для иконки
   */
  icon?: JSX.Element;
  /**
   * @description React элемент как сложное содержимое кнопки
   */
  children?: React.ReactNode;
}

export const Button = ({ size = 'medium', label = 'Кнопка', ...props }: ButtonProps) => {
  const { isOutlined, classNames, onClick, isDisabled, icon, children, ...rest } = props;

  return (
    <button
      {...(onClick && { onClick: (e) => onClick(e) })}
      className={[
        'button',
        isOutlined ? 'outlined' : '',
        `button-${size}`,
        icon ? 'button-icon' : '',
        classNames,
      ].join(' ')}
      disabled={isDisabled}
      {...rest}
    >
      {icon}
      {label}
      {children}
    </button>
  );
};
