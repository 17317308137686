import { RefObject } from 'react';
import Modal from 'shared/ui/Modal/Modal';
import { CustomScroll } from 'react-custom-scroll';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import './RulesModal.scss';

interface RulesModalProps {
  isOpen: boolean;
  onClose: () => void;
  container: HTMLElement | RefObject<HTMLDivElement> | null;
}
export const RulesModal = ({ isOpen, onClose, container }: RulesModalProps) => {
  return (
    <Modal isOpen={isOpen} onClose={onClose} container={container} cls="modal-rules">
      <div className="scroll-view">
        <CustomScroll
          className="scrollbar"
          handleClass="scrollbar-handle"
          heightRelativeToParent="calc(100%)"
        >
          <Title type={'h2'} text="Как играть?" />
          <Text
            size="default"
            classNames={['numbered']}
            text="Чтобы перепрыгнуть препятствие, нажмите левую кнопку мыши или тапните по экрану смартфона пальцем."
          />
          <Text
            size="default"
            classNames={['numbered']}
            text="По пути вам будут встречаться игровые мили и рюкзачки — это хорошие предметы. Собирайте их, чтобы получить баллы или замедлить скорость движения. В некоторых рюкзачках прячутся промокоды от наших партнёров."
          />
          <Text
            size="default"
            classNames={['numbered']}
            text="На одну игру у вас есть 3 жизни. Столкнётесь с препятствием — жизнь сгорит. Потратите 3 жизни — игра закончится, но можно будет сыграть снова."
          />
          <button
            className="link"
            // onClick={() => setIsOpenRules(true)}
          >
            Полные правила
          </button>
          <Title type={'h2'} text="Призовой фонд игры" />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
          <Text
            size="default"
            text="Главный приз игры — <strong>250 000 миль</strong>. Выиграет тот, кто наберет больше всех баллов и займет первое место в турнирной таблице. <strong>150 000 миль</strong> получит игрок, который займет второе место. <strong>100 000 миль</strong> достанутся игроку, который займет третье"
          />
        </CustomScroll>
      </div>
    </Modal>
  );
};
