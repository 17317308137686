/* eslint-disable jsx-a11y/anchor-has-content */
import { FC, useState, useRef, useEffect } from 'react';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Input } from 'shared/ui/Input/Input';
import { Button } from 'shared/ui/Button/Button';
import { Controller, useForm } from 'react-hook-form';
import { Checkbox } from 'shared/ui/Checkbox/Checkbox';
import { NavigateFunction, useNavigate } from 'react-router';
import { RulesModal } from 'shared/ui/RulesModal/RulesModal';
import { RegistrationResponse, useCreateUserMutation } from '../../shared/redux/services/authApi';
import { useDispatch } from 'react-redux';
import { setUser } from '../../shared/redux/store/auth/authSlice';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import './index.scss';
import { useAuth } from '../../shared/redux/hooks/useAuth';

interface RegistrationForm {
  email: string;
  isAccepted: boolean;
  nickname: string;
}

const RegistrationForm: FC = () => {
  const registrationRef = useRef<HTMLDivElement>(null);

  const [createUser] = useCreateUserMutation();
  const dispatch: Dispatch<UnknownAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const { control, handleSubmit, setError } = useForm<RegistrationForm>({
    defaultValues: {
      email: '', // Set initial value for email
      isAccepted: true, // Boolean for the checkbox
      nickname: '', // Set initial value for nickname
    },
  });
  const [isOpen, setOpen] = useState(false);
  const [modalNode, setModalNode] = useState<HTMLDivElement | null>(null);
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    setModalNode(registrationRef.current);
  }, []);

  // eslint-disable-next-line no-unused-vars
  const onSubmit: (data: RegistrationForm) => void = async (data: RegistrationForm) => {
    console.log(data);
    if (!data.isAccepted) return;

    const fdata = new FormData();
    for (const key in data) {
      fdata.append(key, data[key as keyof typeof data] as string);
    }

    console.log('fdata ', fdata);

    await createUser(fdata)
      .unwrap()
      .then((response: RegistrationResponse) => {
        const {
          error,
          success,
          error_text,
          fields: { nickname, email },
        } = response;

        if (!error && success) {
          // Set credentials in redux store
          dispatch(setUser({ email: data.email, nickname: data.nickname }));
          // redirect user to rules page
          navigate('/rules');
        } else {
          toast.error(error_text);
          if (email) {
            setError('email', {
              type: 'manual',
              message: email[0][0],
            });
          }
          if (nickname) {
            setError('nickname', {
              type: 'manual',
              message: nickname[0][0],
            });
          }
          // TODO: delete after validation on server will be fixed
          setTimeout(() => {
            dispatch(setUser({ email: data.email, nickname: data.nickname }));
            // redirect user to rules page
            navigate('/rules');
          }, 5000);
        }
      })
      .catch((err: any) => {
        console.log('err ', err);
        console.log('Invalid email or nickname');
      });
  };

  return (
    <div className="registration-container" ref={registrationRef}>
      <Title type={'h1'} text="Зарегистрируйтесь в игре" />
      <Text text="Чтобы начать играть, введите свою почту и придумайте никнейм" />
      <form className="registration-form" id="registrationForm" onSubmit={handleSubmit(onSubmit)}>
        <Controller
          control={control}
          name="email"
          rules={{
            required: 'Введите email',
            pattern: {
              value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
              message:
                'Неправильный email. Попробуйте еще раз: введите латинскими буквами в формате email@email.ru.',
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              placeholder="Введите свой email"
              dangerText={fieldState.error?.message}
              isDanger={fieldState.invalid}
              {...field}
            />
          )}
        />
        <Controller
          control={control}
          name="isAccepted"
          render={({ field }) => (
            <Checkbox
              text="Нажимая на кнопку вы даёте согласие на обработку персональных данных и соглашаетесь получать информационные рассылки во время действия акции «В погоне за милями»"
              checked={field.value}
              onChange={field.onChange}
            />
          )}
        />
        <Controller
          control={control}
          name="nickname"
          rules={{
            required: 'Введите nickname',
            pattern: {
              value: /^[0-9a-zA-Z]+$/,
              message: 'Пожалуйста, используйте латинские буквы для никнейма.',
            },
          }}
          render={({ field, fieldState }) => (
            <Input
              placeholder="Введите никнейм из латинских букв"
              dangerText={fieldState.error?.message}
              isDanger={fieldState.invalid}
              {...field}
            />
          )}
        />
        <Button label="Сохранить" size="medium" />
      </form>
      <div
        role={'button'}
        tabIndex={0}
        className="link"
        onClick={() => setOpen((prev) => !prev)}
        onKeyDown={() => setOpen((prev) => !prev)}
      >
        Краткие правила акции и призовой фонд
      </div>
      {mounted && (
        <RulesModal isOpen={isOpen} onClose={() => setOpen(false)} container={modalNode} />
      )}
    </div>
  );
};

export default RegistrationForm;
