import { BaseQueryApi, createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { apiUrlFull } from 'shared/config/config';
import { RootState } from '../store/store';
import { logOut, setAccessToken, setUuid } from '../store/auth/authSlice';
import { removeContexts, saveState } from '../store/browser-storage';
import { toast } from 'react-toastify';
import { isTokenExpired } from '../../tools/parseJwt';

const baseQuery = fetchBaseQuery({
  baseUrl: apiUrlFull,
  credentials: 'include',
  prepareHeaders: (headers: Headers, { getState }: any) => {
    const token = (getState() as RootState).auth.access_token;
    if (token && !isTokenExpired(token)) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    return headers;
  },
});

const baseQueryWithReAuth = async (args: any, api: BaseQueryApi, extraOptions: any) => {
  let result = await baseQuery(args, api, extraOptions);
  // try to get a new token
  if ((result?.error as any)?.status === 401) {
    const refresh = (api.getState() as RootState)?.auth.refresh_token;

    if (!refresh || isTokenExpired(refresh)) {
      console.log('Refresh токен протух');
      api.dispatch(logOut());
      saveState((api.getState() as RootState)?.auth);
      removeContexts();
      localStorage.removeItem('promoCodeInfoFinal');
      sessionStorage.removeItem('promoCodes');
      window.location.href = '/';
      toast.error('Вы не авторизованы! Войдите или зарегистрируйтесь пожалуйста.');
      return result;
    }

    const refreshResult = await baseQuery(
      {
        url: 'auth/token/refresh/',
        method: 'POST',
        body: JSON.stringify({ refresh }),
        headers: { 'Content-Type': 'application/json' },
      },
      { ...api },
      extraOptions
    );

    if (refreshResult?.data) {
      const data: any = refreshResult?.data;
      // RefreshResponse
      // store the new token
      api.dispatch(setAccessToken({ access_token: data?.access }));
      api.dispatch(setUuid({ uuid: '' }));
      removeContexts();
      // retry the initial query
      result = await baseQuery(args, api, extraOptions);
    } else {
      api.dispatch(logOut());
      saveState((api.getState() as RootState)?.auth);
      removeContexts();
      localStorage.removeItem('promoCodeInfoFinal');
      sessionStorage.removeItem('promoCodes');
      window.location.href = '/';
      toast.error('Вы не авторизованы! Войдите или зарегистрируйтесь пожалуйста.');
    }
  }

  return result;
};

// Define a service using a base URL and expected endpoints
export const baseApi = createApi({
  reducerPath: 'baseApi',
  baseQuery: baseQueryWithReAuth,
  endpoints: () => ({}),
  tagTypes: ['Nickname', 'Results'],
});
