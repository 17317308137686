import React, {
  createContext,
  useContext,
  useEffect,
  useRef,
  useState,
  useCallback,
  useMemo,
  ReactNode,
} from 'react';
import { useSelector } from 'react-redux';
import { selectCurrentUuid } from '../redux/store/auth/authSlice';
import { loadContext, saveContext, removeContext } from '../redux/store/browser-storage';

export const enum ReadyState {
  CONNECTING = 0,
  OPEN = 1,
  CLOSING = 2,
  CLOSED = 3,
  UNINSTANTIATED = -1,
}

export const enum webSocketMessages {
  START = 'start', // начало текущей игровой сессии
  ADD_REFERRAL_CODE = 'add_referral_code', // игрок ввел реферальный код
  NEW_GAME = 'new_game', // игрок закончил игру и тут же хочет сыграть заново
  SPEED_UP = 'speed_up', // игра усложняется и повышается кол-во очков в секунду
  END = 'end', // завершение текущей игровой сессии
  SCORE_PICKUP = 'score_pickup', // игрок поднял сущность, дающую только очки
  PROMOCODE_PICKUP = 'promocode_pickup', // игрок поднял сущность, замедляющую скорость бега и дающую промокод
  DECREASE_LIFE_COUNT = 'decrease_life_count', // игрок столкнулся с сущностью, уменьшающей количество жизней игрока в текущей игровой сессии на один
}

interface WebSocketContextType {
  connected: boolean;
  messages: any[];
  sentMessages: any[];
  lastMessage: any | null;
  error: Event | null;
  sendMessage: (message: any) => void;
  setWebSocketUrl: (url: string) => void;
  saveShouldReconnect: (flag: boolean) => void;
  currentUrl: string | null;
  readyState: number;
  connectionStatus: string;
}

const WebSocketContext = createContext<WebSocketContextType | undefined>(undefined);

interface WebSocketProviderProps {
  children: ReactNode;
}

export const WebSocketProvider: React.FC<WebSocketProviderProps> = ({ children }) => {
  const uuid = useSelector(selectCurrentUuid);
  const [connected, setConnected] = useState<boolean>(() => {
    const savedConnected = uuid ? loadContext('connected') : null;
    return savedConnected ?? false;
  });
  const [messages, setMessages] = useState<any[]>(() => {
    const savedMessages = uuid ? loadContext('messages') : null;
    return savedMessages ?? [];
  });
  const [sentMessages, setSentMessages] = useState<any[]>(() => {
    const savedSentMessages = uuid ? loadContext('sentMessages') : null;
    return savedSentMessages ?? [];
  });
  const [lastMessage, setLastMessage] = useState<any>(null);
  const [error, setError] = useState<Event | null>(null);
  const [readyState, setReadyState] = useState<number>(ReadyState.UNINSTANTIATED);
  const socketRef = useRef<WebSocket | null>(null);
  const urlRef = useRef<string | null>(uuid ? loadContext('websocketUrl') : '');
  const shouldReconnectRef = useRef<boolean>(
    (uuid ? loadContext('shouldReconnect') : null) ?? true
  );

  const connectionStatus = useMemo(
    () =>
      ({
        [ReadyState.CONNECTING]: 'Connecting',
        [ReadyState.OPEN]: 'Open',
        [ReadyState.CLOSING]: 'Closing',
        [ReadyState.CLOSED]: 'Closed',
        [ReadyState.UNINSTANTIATED]: 'Uninstantiated',
      })[readyState] || 'Unknown',
    [readyState]
  );

  const saveShouldReconnect = useCallback((flag: boolean) => {
    console.log('saveShouldReconnect ', flag);

    shouldReconnectRef.current = flag;
    saveContext('shouldReconnect', flag);
  }, []);

  const connectWebSocket = useCallback(() => {
    console.log(
      'connectWebSocket - url.current ',
      urlRef.current,
      ' shouldReconnectRef.current ',
      shouldReconnectRef.current
    );

    if (!urlRef.current || !shouldReconnectRef.current) return;

    if (socketRef.current) {
      socketRef.current.close();
    }

    socketRef.current = new WebSocket(urlRef.current);

    setReadyState(socketRef.current.readyState);

    socketRef.current.onopen = () => {
      setConnected(true);
      saveContext('connected', true);
      setReadyState(socketRef.current?.readyState || ReadyState.UNINSTANTIATED);
      setError(null);
    };

    socketRef.current.onmessage = (event) => {
      const message = JSON.parse(event.data);
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, message];
        saveContext('messages', updatedMessages);
        return updatedMessages;
      });
      setLastMessage(message); // Сохраняем последнее сообщение
    };

    socketRef.current.onerror = (err) => {
      console.error(err);
      setError(err);
    };

    socketRef.current.onclose = () => {
      setConnected(false);
      saveContext('connected', false);
      setReadyState(socketRef.current?.readyState || ReadyState.UNINSTANTIATED);
      console.log('shouldReconnectRef.current ', shouldReconnectRef.current);
      if (shouldReconnectRef.current) setTimeout(connectWebSocket, 3000); // Попытка повторного подключения через 3 секунды
      socketRef.current = null;
      setMessages(() => {
        removeContext('messages');
        return [];
      });
      setSentMessages(() => {
        removeContext('sentMessages');
        return [];
      });
    };
  }, []);

  useEffect(() => {
    connectWebSocket();

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };
  }, [urlRef.current, shouldReconnectRef.current]);

  const sendMessage = useCallback((message: any) => {
    if (socketRef.current && socketRef.current.readyState === WebSocket.OPEN) {
      socketRef.current.send(JSON.stringify(message));
      setSentMessages((prevMessages) => {
        const updatedMessages = [...prevMessages, message];
        saveContext('sentMessages', updatedMessages);
        return updatedMessages;
      });
    }
  }, []);

  const setWebSocketUrl = useCallback((newUrl: string) => {
    urlRef.current = newUrl;
    saveContext('websocketUrl', newUrl);
  }, []);

  useEffect(() => {
    const handleBeforeUnload = () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
    };

    window.addEventListener('beforeunload', handleBeforeUnload);

    return () => {
      window.removeEventListener('beforeunload', handleBeforeUnload);
    };
  }, []);

  const contextValue = useMemo(
    () => ({
      connected,
      messages,
      sentMessages,
      lastMessage,
      error,
      sendMessage,
      setWebSocketUrl,
      currentUrl: urlRef.current,
      readyState,
      connectionStatus,
      saveShouldReconnect,
    }),
    [
      connected,
      messages,
      sentMessages,
      lastMessage,
      error,
      sendMessage,
      setWebSocketUrl,
      urlRef,
      readyState,
      connectionStatus,
      saveShouldReconnect,
    ]
  );

  return <WebSocketContext.Provider value={contextValue}>{children}</WebSocketContext.Provider>;
};

export const useWebSocket = (): WebSocketContextType => {
  const context = useContext(WebSocketContext);
  if (!context) {
    throw new Error('useWebSocket must be used within a WebSocketProvider');
  }
  return context;
};
