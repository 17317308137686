import './Title.scss';

interface TitleProps {
  /**
   * @description Определяет семантический тэг заголовка h1 или h2
   */
  type: 'h1' | 'h2';
  /**
   * @description Содержимое заголовка в виде текста
   */
  text?: string;
}

export const Title = ({ type = 'h1', ...props }: TitleProps) => {
  if (type === 'h2') return <h2>{props.text}</h2>;
  return <h1>{props.text}</h1>;
};
