import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useAuthMutation } from '../../shared/redux/services/authApi';
import { setTokens } from '../../shared/redux/store/auth/authSlice';
import generateHMAC from '../../shared/tools/generateHMAC';
import './index.scss';

const AuthCallback = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [auth, { isLoading }] = useAuthMutation();
  const [mounted, setMounted] = useState(false);

  useEffect(() => {
    setMounted(true);
  }, []);

  useEffect(() => {
    if (!mounted) return;

    // Получаем параметры из URL
    const urlParams = new URLSearchParams(window.location.search); // #access_token=...&id_token=...
    const accessToken = urlParams.get('access_token');
    console.log('window.location ', window.location);
    console.log('accessToken ', accessToken);

    if (accessToken) {
      if (isLoading) return;

      const getTokens = async () => {
        const secretKey = process.env.REACT_APP_SECRET_KEY ?? '';
        console.log('secretKey:', secretKey);

        const hmac = await generateHMAC(secretKey, accessToken);
        console.log('HMAC:', hmac);

        const fdata = new FormData();
        fdata.append('access_token', accessToken);
        fdata.append('key', hmac);

        console.log('fdata ', fdata);

        await auth(fdata)
          .unwrap()
          .then((authData: any) => {
            const { refresh_token, access_token, user_created } = authData;
            dispatch(setTokens({ refresh_token, access_token, user_created }));
            navigate(user_created ? '/registration' : '/rules');
          })
          .catch((error) => {
            console.log('err ', error);
            console.log('err.data ', error.data);
          });
      };

      getTokens();
    } else {
      console.error('Access token or ID token not found in the URL.');
      // Обработка ошибки, если токены не были найдены
    }
  }, [mounted]);

  return <h2>Выполняем авторизацию...</h2>;
};

export default AuthCallback;
