import './Text.scss';

interface TextProps {
  /**
   * @description Отображаемый текст
   */
  text?: string;
  /**
   * @description размер текста; default - 18px; small - 12px
   */
  size?: 'default' | 'small';
  /**
   * @description Массив из классов
   */
  classNames?: Array<string>;
}

export const Text = ({ size = 'default', text = 'Lorem ipsum', ...props }: TextProps) => {
  return (
    <p
      className={['text', size, props.classNames].join(' ')}
      dangerouslySetInnerHTML={{ __html: text }}
    />
  );
};
