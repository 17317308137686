import Marquee from 'react-fast-marquee';
import { Button } from '../Button/Button';
import { FC } from 'react';
import { useNavigate } from 'react-router';
import './Footer.scss';
import {
  A101hotelsSVG,
  askonaSVG,
  gruzovichkoffSVG,
  holodilnikruSVG,
  litresSVG,
  mtsSVG,
  otelloSVG,
  renesansSVG,
  samsonSVG,
  shevMarketSVG,
  siriusHotelSVG,
  stoletovSVG,
  TvilSVG,
  unode50SVG,
} from '../../resourcesBase64/images/footerLogos';

interface FooterProps {
  /**
   * @description Отвечает за отображение блока с партнёрами
   */
  isPartnersBlockEnabled?: boolean;
  /**
   * @description Текущая страница
   */
  page?: string;
}

const Footer: FC<FooterProps> = ({ isPartnersBlockEnabled, page }) => {
  return (
    <div className="footer">
      {isPartnersBlockEnabled && <FooterPartners />}
      <FooterContent page={page} />
    </div>
  );
};

const FooterPartners: FC = () => {
  const logoList = [
    { id: 1, src: gruzovichkoffSVG, height: 40 },
    { id: 2, src: A101hotelsSVG },
    { id: 3, src: askonaSVG, height: 45 },
    { id: 4, src: holodilnikruSVG },
    { id: 5, src: litresSVG },
    { id: 6, src: mtsSVG, height: 50 },
    { id: 7, src: otelloSVG },
    { id: 8, src: renesansSVG, height: 35 },
    { id: 9, src: samsonSVG, height: 55 },
    { id: 10, src: shevMarketSVG, height: 40 },
    { id: 11, src: siriusHotelSVG },
    { id: 12, src: stoletovSVG, height: 40 },
    { id: 13, src: TvilSVG, height: 50 },
    { id: 14, src: unode50SVG },
  ];

  return (
    <div className="partners">
      <Marquee autoFill>
        {logoList.map(({ id, src, height }) => (
          <div className="marquee-item" key={id}>
            <img style={{ height }} src={src} alt="" />
          </div>
        ))}
      </Marquee>
    </div>
  );
};

interface FooterContentProps {
  /**
   * @description Текущая страница
   */
  page?: string;
}

const FooterContent: FC<FooterContentProps> = ({ page }) => {
  const navigate = useNavigate();
  const isAccount = page === 'account';
  const isBtnDisbaled = page === '' || page === 'start';

  return (
    <div className="footer-content">
      <div className="container">
        <div className="left-side">
          <div className="links">
            <a href="/">Правила акции</a>
            <a href="/">Политика конфиденциальности</a>
          </div>
          <span>© Авиакомпания «Аэрофлот» 2008-2024</span>
        </div>
        <div className="right-side">
          <Button
            isOutlined
            label={`${isAccount ? 'Вернуться в игру' : 'Личный кабинет игры'}`}
            isDisabled={isBtnDisbaled}
            size="small"
            onClick={() => navigate(`${isAccount ? '/rules' : '/account'}`)}
          />
        </div>
      </div>
    </div>
  );
};

export default Footer;
