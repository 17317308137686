import { FC, useState, useEffect } from 'react';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { CustomScroll } from 'react-custom-scroll';
import Modal from '../../shared/ui/Modal/Modal';
import {
  UserInfo,
  ReferralCodeInfo,
  type PromoCodeInfo,
  LeaderBoardInfo,
  dataTypes,
  usePrefetch,
  useGetResultsQuery,
} from '../../shared/redux/services/resultsApi';
import { selectCurrentNickname, setNickname } from '../../shared/redux/store/auth/authSlice';
import {
  RegistrationResponse,
  useUpdateNicknameMutation,
} from '../../shared/redux/services/authApi';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { usePromoCodesFinal } from '../../shared/context/PromoCodesContext';
import { toast } from 'react-toastify';
import { Controller, useForm } from 'react-hook-form';
import { Input } from '../../shared/ui/Input/Input';
import { openNewSafeWindow } from '../../shared/tools/openNewSafeWindow';
import { useWebSocket } from '../../shared/context/WebSocketContext';
import { accountBackSVG } from '../../shared/resourcesBase64/images/backs';
import { editSVG, doneSVG, copySVG, tgSVG, vkSVG } from '../../shared/resourcesBase64/images/icons';
import './index.scss';

const ImageWithPlaceholder = ({ src, alt }: { src: string; alt: string }) => {
  const [imageError, setImageError] = useState(false);

  return (
    <div className="image-container" data-alt={alt}>
      {!imageError && <img src={src} alt="" onError={() => setImageError(true)} />}
    </div>
  );
};

interface ModalPromoProps {
  id?: number;
  url?: string;
  copied?: boolean;
}

export interface PromoCodeInfoFinal extends PromoCodeInfo {
  copied?: boolean;
}

const Account: FC = () => {
  const { saveShouldReconnect } = useWebSocket();

  const [viewportHeight, setViewportHeight] = useState(window.innerHeight);
  const [isDesktop, setIsDesktop] = useState(window.matchMedia('(min-width: 1024px)').matches);
  const [isTablet, setIsTablet] = useState(window.matchMedia('(min-width: 480px)').matches);
  const [matches, setMatches] = useState(
    !(isTablet || isDesktop) || (isTablet && !isDesktop && viewportHeight < 1000)
  );

  const [isLeftSideOpened, setIsLeftSideOpened] = useState(true);

  const { data: result, isLoading, isFetching } = useGetResultsQuery();
  const nickname = useSelector(selectCurrentNickname);
  const [userInfo, setUserInfo] = useState<UserInfo>();
  const [referralCodeInfo, setReferralCodeInfo] = useState<ReferralCodeInfo[]>();
  const [promoCodeInfo, setPromoCodeInfo] = useState<PromoCodeInfo[]>();
  const { promoCodeInfoFinal, savePromoCodeInfoFinal } = usePromoCodesFinal();
  const [leaderBoardInfo, setLeaderBoardInfo] = useState<LeaderBoardInfo[]>();
  const [mounted, setMounted] = useState(false);
  const [otherSpace, setOtherSpace] = useState(0);
  const [isOpenPromo, setIsOpenPromo] = useState(false);
  const [openedPromo, setOpenedPromo] = useState<ModalPromoProps>({});
  const [copied, setCopied] = useState(false);
  const [referralToShare, setReferralToShare] = useState<string>('');

  const prefetchPage = usePrefetch('getResults');

  useEffect(() => {
    const style = document.createElement('style');
    style.innerHTML = `
      .layout-account {
        background-image: url('${accountBackSVG}');
      }
    `;
    document.head.appendChild(style);
    return () => {
      document.head.removeChild(style);
    };
  }, []);

  useEffect(() => {
    saveShouldReconnect(false);
    setMounted(true);
  }, []);

  useEffect(() => {
    prefetchPage();
  }, []);

  useEffect(() => {
    if (!promoCodeInfo) return;

    const newpromoCodeInfo = promoCodeInfo.map((code) => {
      const flag = promoCodeInfoFinal?.find((c) => c.id === code.id)?.copied;
      return { ...code, ...(flag && { copied: true }) };
    });
    savePromoCodeInfoFinal(newpromoCodeInfo);
  }, [promoCodeInfo]);

  useEffect(() => {
    window
      .matchMedia('(min-width: 1024px)')
      .addEventListener('change', (e) => setIsDesktop(e.matches));
    window
      .matchMedia('(min-width: 480px)')
      .addEventListener('change', (e) => setIsTablet(e.matches));
  }, []);

  useEffect(() => {
    setMatches(!(isTablet || isDesktop) || (isTablet && !isDesktop && viewportHeight < 1000));
  }, [isDesktop, isTablet, viewportHeight]);

  useEffect(() => {
    const getOtherSpaces = () => {
      // Находим элемент по классу
      const contentDiv = document.querySelector('.content');
      const footerDiv = document.querySelector('.footer');
      const leaderboardDiv = document.querySelector('.leaderboard');
      let contentMarginTop = 0,
        footerHeight = 0,
        leaderboardMarginBottom = 0;

      if (contentDiv) {
        // Получаем стили элемента
        const { marginTop = '0' } = window.getComputedStyle(contentDiv);
        contentMarginTop = Number(marginTop.replace('px', ''));
        // Пример: Получаем конкретное свойство стиля
      }
      if (footerDiv) {
        // Получаем стили элемента
        const { height = '0' } = window.getComputedStyle(footerDiv);
        footerHeight = Number(height.replace('px', ''));
        // Пример: Получаем конкретное свойство стиля
      }
      if (leaderboardDiv) {
        // Получаем стили элемента
        const { marginBottom = '0' } = window.getComputedStyle(leaderboardDiv);
        leaderboardMarginBottom = Number(marginBottom.replace('px', ''));
        // Пример: Получаем конкретное свойство стиля
      }
      setOtherSpace(contentMarginTop + leaderboardMarginBottom + footerHeight);

      setViewportHeight(window.innerHeight); // Обновляем высоту при изменении окна
    };
    getOtherSpaces();

    window.addEventListener('resize', getOtherSpaces);

    return () => {
      window.removeEventListener('resize', getOtherSpaces);
    };
  }, []);

  useEffect(() => {
    if (!result) return;

    const user = result.find((section) => section.category === dataTypes[0])?.data;
    setUserInfo(user as UserInfo);
    const referrals = result.find((section) => section.category === dataTypes[1])?.data;
    setReferralCodeInfo(referrals as ReferralCodeInfo[]);
    const promos = result.find((section) => section.category === dataTypes[2])?.data;
    setPromoCodeInfo(promos as PromoCodeInfo[]);
    const results = result.find((section) => section.category === dataTypes[3])?.data;
    setLeaderBoardInfo(results as LeaderBoardInfo[]);
  }, [result]);

  const handleShowPromoCode = (id: number, url: string, copied: boolean | undefined) => {
    setOpenedPromo({ id, url, ...(copied && { copied: true }) });
    setIsOpenPromo(true);
  };

  const handlePromoCopy = (id: number | undefined) => {
    if (!id) return;

    navigator.clipboard.writeText(`${id}`);
    setCopied(() => {
      setTimeout(() => {
        setCopied(false);
      }, 2000);
      return true;
    });
    setTimeout(() => {
      setOpenedPromo((prev) => {
        if (prev.copied) return { ...prev };
        savePromoCodeInfoFinal(
          promoCodeInfoFinal.map((code) =>
            code.id === prev.id ? { ...code, copied: true } : { ...code }
          )
        );
        return { ...prev, copied: true };
      });
    }, 2000);
  };

  const handleReferralCopy = (code: string) => {
    if (!code) return;

    navigator.clipboard.writeText(code);
    setReferralToShare(code);
  };

  const handleShare = (url: string) => {
    let codeToShare = referralToShare;

    if (!codeToShare) {
      navigator.clipboard.readText().then((clipText) => {
        if (!clipText) return;

        codeToShare = clipText;
      });
    }

    if (!codeToShare) return;

    if (navigator.share) {
      console.log('codeToShare ', codeToShare);

      navigator
        .share({
          title: 'Реферальный код для игры',
          text: `Используй реферальный код ${codeToShare} для игры Аэрофлот`,
          url: document.location.href,
        })
        .then(() => {
          console.log('Successfully shared');
          toast.success('Успешно поделились!');
        })
        .catch((error) => {
          console.error('Something went wrong sharing the code', error);
        });
    } else {
      openNewSafeWindow(url);
    }
  };

  return (
    <div className="container">
      <div className="leaderboard" style={{ height: `calc(100vh - ${otherSpace}px)` }}>
        <div className="leaderboard-header">
          <div className="left-side">
            <EditField value={nickname ?? userInfo?.nickname ?? ''} />
          </div>
        </div>
        <div className="leaderboard-content">
          {(matches ? isLeftSideOpened : true) && (
            <div className="left-side">
              <Title type={'h2'} text="Мои бонусы и призы" />
              <div className="scroll-view">
                <CustomScroll
                  className="scrollbar"
                  handleClass="scrollbar-handle"
                  heightRelativeToParent="calc(100%)"
                >
                  {promoCodeInfoFinal?.map(
                    ({ id, image, description, url, is_used, copied }, index) => (
                      <div className="row" key={id}>
                        <ImageWithPlaceholder src={image} alt="лого" />
                        <Text size="default" classNames={['text']} text={description} />
                        <Button
                          size="small"
                          isDisabled={is_used}
                          label="Показать"
                          classNames={['promo-code']}
                          onClick={() => handleShowPromoCode(id, url, copied)}
                          {...(copied && {
                            icon: <span className="promo-code-copied" title="Просмотрен" />,
                          })}
                        />
                      </div>
                    )
                  )}
                </CustomScroll>
              </div>
            </div>
          )}
          {(matches ? !isLeftSideOpened : true) && (
            <div className="right-side">
              <Title type={'h2'} text="Турнирная таблица" />
              <div className="scroll-view">
                <CustomScroll
                  className="scrollbar"
                  handleClass="scrollbar-handle"
                  heightRelativeToParent="calc(100%)"
                >
                  {leaderBoardInfo?.map(({ position, nickname, id, score, is_current_user }) => (
                    <div className={`row ${is_current_user ? 'current-player' : ''}`} key={id}>
                      <Text size="default" classNames={['place']} text={String(position)} />
                      <Text size="default" classNames={['name']} text={nickname} />
                      <Text size="default" classNames={['id']} text={id} />
                      <Text size="default" classNames={['score']} text={String(score)} />
                    </div>
                  ))}
                </CustomScroll>
              </div>
            </div>
          )}
          {matches && (
            <div className="mobile-switcher">
              <button
                className={`switcher-left-side ${isLeftSideOpened ? 'active' : ''}`}
                onClick={() => setIsLeftSideOpened(true)}
              />
              <button
                className={`switcher-right-side ${isLeftSideOpened ? '' : 'active'}`}
                onClick={() => setIsLeftSideOpened(false)}
              />
            </div>
          )}
        </div>
        <div className="leaderboard-footer">
          <div className="typography">
            <span style={{ fontWeight: 700 }}>Приглашай друзей и увеличивай свои баллы</span>
            <span style={{ fontWeight: 400, lineHeight: '14px' }}>
              Поделитесь реферальным кодом в социальных сетях,{' '}
              <span className="responsive-break"></span>чтобы получить дополнительные 300 баллов
            </span>
          </div>
          <div className="codes-wrapper">
            <div className="codes">
              {[...(referralCodeInfo ?? [])]
                ?.sort((a, b) => Number(b.is_used) - Number(a.is_used))
                .map(({ code, is_used }) => (
                  <div className="code-wrapper" key={code}>
                    <Button
                      size="small"
                      label={code}
                      isDisabled={is_used}
                      classNames={['codes-button']}
                      icon={<img src={copySVG} alt="" />}
                      onClick={() => handleReferralCopy(code)}
                    />
                    {referralToShare === code && (
                      <small className="referral-code-copied">скопирован</small>
                    )}
                  </div>
                ))}
            </div>
            <div className="text-hint">Выберите реферальный код</div>
          </div>
          <div className="socials-wrapper">
            <div className="socials">
              <button onClick={() => handleShare('https://t.me')}>
                <img src={tgSVG} alt="Телеграмм" />
              </button>
              <button onClick={() => handleShare('https://vk.com')}>
                <img src={vkSVG} alt="ВКонтакте" />
              </button>
            </div>
            <div className="text-hint">Поделитесь с друзьями</div>
          </div>
        </div>
      </div>
      {mounted && (
        <Modal
          isOpen={isOpenPromo}
          onClose={() => {
            setIsOpenPromo(false);
            setOpenedPromo({});
          }}
          cls="modal-promo"
          container={document.querySelector('.leaderboard') as HTMLElement}
        >
          <Title type={'h2'} text="Промокод" />
          <div className="promo-code-wrapper">
            <div className="promo-code-content">
              <div className="promo-code-text" title={openedPromo?.copied ? 'Скопирован' : ''}>
                {openedPromo?.id}
              </div>
              <button
                className="promo-code-copy"
                title="Cкопировать"
                onClick={() => handlePromoCopy(openedPromo?.id)}
              >
                <img src={copySVG} alt="Cкопировать" />
              </button>
            </div>
            {copied && <small className="promo-code-copied">Скопирован</small>}
            {openedPromo?.copied && <small className="promo-code-viewed">просмотрен</small>}
          </div>
          <Button label="" size="small">
            <a href={openedPromo?.url} target="_blank" rel="noopener noreferrer">
              Применить на сайте партнера
            </a>
          </Button>
          <Text size="small" text={`* ${openedPromo?.url}`} />
        </Modal>
      )}
    </div>
  );
};

type EditFieldProp = {
  value: string;
};

interface NicknameForm {
  nickname: string;
}

const EditField: FC<EditFieldProp> = ({ value }) => {
  const [isEditing, setIsEditing] = useState<boolean>(false);
  const { control, setValue, getValues, setError } = useForm<NicknameForm>({
    defaultValues: {
      nickname: '', // Set initial value for nickname
    },
  });

  const [updateNickname] = useUpdateNicknameMutation();
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  useEffect(() => {
    setValue('nickname', value);
  }, [value]);

  const handleClick = async () => {
    if (!isEditing) {
      setIsEditing((prev) => !prev);
      return;
    }

    const fdata = new FormData();
    const { nickname } = getValues();
    if (!nickname) return;

    fdata.append('nickname', nickname);
    const newNickname = nickname;

    await updateNickname(fdata)
      .unwrap()
      .then((response: RegistrationResponse) => {
        const {
          error,
          success,
          error_text,
          fields: { nickname },
        } = response;
        if (!error && success) {
          dispatch(setNickname({ nickname: newNickname }));
          setIsEditing((prev) => !prev);
          toast.success('Никнейм успешно обновлен!');
        } else {
          toast.error(error_text);
          if (nickname) {
            setError('nickname', {
              type: 'manual',
              message: nickname[0][0],
            });
          }
        }
      })
      .catch((err: any) => {
        console.log('err ', err);
        console.log('Invalid nickname');
      });
  };

  return (
    <div className="edit-field">
      {isEditing ? (
        <form className="nickname-form">
          <Controller
            control={control}
            name="nickname"
            rules={{
              required: 'Введите nickname',
              pattern: {
                value: /^[0-9a-zA-Z]+$/,
                message: 'Пожалуйста, используйте латинские буквы для никнейма...',
              },
            }}
            render={({ field, fieldState }) => (
              <Input
                placeholder="Введите nickname"
                dangerText={fieldState.error?.message}
                isDanger={fieldState.invalid}
                disabled={!isEditing}
                className={`nickname-input${isEditing ? '-editable' : ''}`}
                {...field}
              />
            )}
          />
        </form>
      ) : (
        <span className="nickname-value">{value}</span>
      )}
      <button onClick={handleClick}>
        <img
          src={isEditing ? doneSVG : editSVG}
          alt={isEditing ? 'Сохранить никнейм' : 'Редактировать никнейм'}
        />
      </button>
    </div>
  );
};

export default Account;
