import { TwoSidesLayout } from 'pages';
import { FC } from 'react';
import RegistrationForm from './RegistrationForm';
import './../index.scss';

const RegistrationPage: FC = () => {
  return (
    <TwoSidesLayout>
      <RegistrationForm />
    </TwoSidesLayout>
  );
};

export default RegistrationPage;
