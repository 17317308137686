import React, { createContext, useContext, useState, useCallback, useMemo, ReactNode } from 'react';
import { PromoCodeInfoFinal } from '../../pages/account/Account';

interface PromoCodesContextType {
  promoCodeInfoFinal: PromoCodeInfoFinal[];
  savePromoCodeInfoFinal: (promoCodes: PromoCodeInfoFinal[]) => void;
}

const PromoCodesContext = createContext<PromoCodesContextType | undefined>(undefined);

interface PromoCodesProviderProps {
  children: ReactNode;
}

export const PromoCodesProvider: React.FC<PromoCodesProviderProps> = ({ children }) => {
  const [promoCodeInfoFinal, setPromoCodeInfoFinal] = useState<PromoCodeInfoFinal[]>(() => {
    const savedPromoCodeInfoFinal = localStorage.getItem('promoCodeInfoFinal');
    return savedPromoCodeInfoFinal ? JSON.parse(savedPromoCodeInfoFinal) : null;
  });

  const savePromoCodeInfoFinal = useCallback((promoCodes: PromoCodeInfoFinal[]) => {
    setPromoCodeInfoFinal(() => {
      localStorage.setItem('promoCodeInfoFinal', JSON.stringify(promoCodes));
      return promoCodes;
    });
  }, []);

  const contextValue = useMemo(
    () => ({
      promoCodeInfoFinal,
      savePromoCodeInfoFinal,
    }),
    [promoCodeInfoFinal, savePromoCodeInfoFinal]
  );

  return <PromoCodesContext.Provider value={contextValue}>{children}</PromoCodesContext.Provider>;
};

export const usePromoCodesFinal = (): PromoCodesContextType => {
  const context = useContext(PromoCodesContext);
  if (!context) {
    throw new Error('useGame must be used within a GameProvider');
  }
  return context;
};
