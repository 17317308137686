import { Link, Outlet, Route, Routes, useLocation } from 'react-router-dom';
import Footer from 'shared/ui/Footer/Footer';
import { Title } from 'shared/ui/Title/Title';
import AuthCallback from './authCallback/AuthCallback';
import StartPage from './start';
import RegistrationPage from './registration';
import AccountPage from './account';
import RulesPage from './rules';
import GamePage from './game';
import FinalPage from './final';
import { useNavigate } from 'react-router';
import { useAuth } from '../shared/redux/hooks/useAuth';
import { heroSVG, heroSVGTablet } from '../shared/resourcesBase64/images/gameHero';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { removeContexts } from '../shared/redux/store/browser-storage';
import { logoSVG } from '../shared/resourcesBase64/images/logo';
import './index.scss';
import { useEffect } from 'react';

const ProtectedRoute = ({ children }: { children: JSX.Element }) => {
  const isAuthenticated = useAuth();
  console.log('isAuthenticated ', isAuthenticated);
  const navigate = useNavigate();

  useEffect(() => {
    if (isAuthenticated) return;

    navigate('/');
  }, [isAuthenticated]);

  return isAuthenticated ? children : null;
};

export const Routing = () => {
  const location = useLocation();
  const { pathname: page } = location;
  const pageOutsideGameList = ['/', '/auth_callback', '/registration'];
  if (pageOutsideGameList.includes(page)) removeContexts();

  return (
    <Routes>
      <Route path="/" element={<FullLayout isPartnersBlockEnabled />}>
        <Route path="/auth_callback" element={<AuthCallback />} />
        <Route path="" element={<StartPage />} />
        <Route
          path="/registration"
          element={
            <ProtectedRoute>
              <RegistrationPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/rules"
          element={
            <ProtectedRoute>
              <RulesPage />
            </ProtectedRoute>
          }
        />
        <Route path="*" element={<NoMatch />} />
      </Route>
      <Route path="/" element={<FullLayout />}>
        <Route
          path="/account"
          element={
            <ProtectedRoute>
              <AccountPage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/game"
          element={
            <ProtectedRoute>
              <GamePage />
            </ProtectedRoute>
          }
        />
        <Route
          path="/final"
          element={
            <ProtectedRoute>
              <FinalPage />
            </ProtectedRoute>
          }
        />
      </Route>
    </Routes>
  );
};

export const TwoSidesLayout = (
  props: Readonly<{ children: JSX.Element; rightSideChilden?: JSX.Element }>
) => {
  return (
    <>
      <div className="container start-page">
        <div className="left-side">
          {props.children}
          <Title type="h2" text="Наши партнеры:" />
        </div>
        <div className="right-side">
          <img className="hero-image" src={heroSVG} alt="изображение с Андреем" />
          <img className="hero-image-tablet" src={heroSVGTablet} alt="изображение с Андреем" />
          {props.rightSideChilden}
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

interface FullLayoutProps {
  /**
   * @description Показ логотипов партнеров
   */
  isPartnersBlockEnabled?: boolean;
}
export const FullLayout = ({ isPartnersBlockEnabled = false }: FullLayoutProps) => {
  const location = useLocation();
  const navigate = useNavigate();

  const { pathname } = location;
  const page = pathname.slice(1);

  return (
    <>
      <div className={`layout${page ? '-' + page : ''}`}>
        <div className="container">
          <button className="header" onClick={() => navigate('/')}>
            <img className="logo" src={logoSVG} alt="logo" />
          </button>
        </div>
        <div className="content">
          <Outlet />
        </div>
        <Footer isPartnersBlockEnabled={isPartnersBlockEnabled} page={page} />
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        closeOnClick
        draggable
        pauseOnHover
        theme="light"
      />
    </>
  );
};

const NoMatch = () => {
  return (
    <div className="page-empty">
      <h2>Тут ничего нет!</h2>
      <p>
        <Link className="link" to="/">
          Вернуться на главную страницу
        </Link>
      </p>
    </div>
  );
};
