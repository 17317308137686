import { Routing } from 'pages';
import { withProviders } from './providers';
import { GameProvider } from '../shared/context/GameContext';
import { WebSocketProvider } from '../shared/context/WebSocketContext';
import { PromoCodesProvider } from '../shared/context/PromoCodesContext';
import './index.scss';

const App = () => {
  return (
    <div className="app">
      <GameProvider>
        <WebSocketProvider>
          <PromoCodesProvider>
            <Routing />
          </PromoCodesProvider>
        </WebSocketProvider>
      </GameProvider>
    </div>
  );
};

export default withProviders(App);
