const generateRandomBoolean = () => (Math.random() + 0.5) >> 0;

const generateRandomInteger = ({ min, max }: { min: number; max: number }) =>
  Math.floor(Math.random() * (max - min + 1)) + min;

const generateRandomString = (length: number) => {
  const charset = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
  let result = '';
  const charsetLength = charset.length;
  for (let i = 0; i < length; i++) {
    result += charset.charAt(Math.floor(Math.random() * charsetLength));
  }
  return result;
};

const getRandomTime = () => generateRandomInteger({ min: 1000, max: 2000 });

export { generateRandomBoolean, generateRandomInteger, generateRandomString, getRandomTime };
