import { combineReducers, configureStore, isRejectedWithValue } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query';
import authSlice from './auth/authSlice';
import { baseApi } from '../services/baseApi';
import type { MiddlewareAPI, Middleware } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';

const reducers = combineReducers({
  auth: authSlice, // This reducer is to set credentials & consume state in application
  [baseApi.reducerPath]: baseApi.reducer, // this reducer has auth header & endpoints
});

// Определим тип данных для payload ошибки
interface RejectedPayload {
  status?: number;
  data?: unknown; // Данные ответа от сервера
  error?: string; // Сообщение об ошибке
}

/**
 * Log a warning and show a toast!
 */
export const rtkQueryErrorLogger: Middleware = (api: MiddlewareAPI) => (next) => (action) => {
  // Проверка, что запрос был отклонен с использованием rejectWithValue
  if (isRejectedWithValue(action)) {
    const payload = action.payload as RejectedPayload;
    console.error('Ошибка запроса:', payload);

    const status = payload.status;
    const data = payload.data;
    const errorMessage =
      payload.error || (data as Record<'error', string>).error || 'Неизвестная ошибка';
    toast.error(`Ошибка запроса: ${status}: ${errorMessage}`);
    console.error('Данные ответа:', data);
  }

  return next(action);
};

export const store = configureStore({
  reducer: reducers,
  // preloadedState: loadState(),
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(baseApi.middleware).concat(rtkQueryErrorLogger),
});

setupListeners(store.dispatch);
export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
