const parseJwt = (token: string) => {
  try {
    // Извлекаем полезную нагрузку (вторая часть токена)
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join('')
    );

    return JSON.parse(jsonPayload);
  } catch (error) {
    console.error('Ошибка при парсинге токена:', error);
    return null;
  }
};

export const isTokenExpired = (token: string) => {
  const decoded = parseJwt(token);
  if (!decoded) {
    return true; // Если токен не удается декодировать, считаем его истекшим
  }

  const currentTime = Math.floor(Date.now() / 1000); // Текущее время в секундах
  return decoded.exp && decoded.exp < currentTime;
};
