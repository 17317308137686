import { Text } from 'shared/ui/Text/Text';
import LifeList from 'shared/ui/LifeList/LifeList';
import { balloonSVG, caseSVG, deskSVG, puddleSVG } from '../../resourcesBase64/images/gameObjects';
import { negativeSVG, positiveSVG } from '../../resourcesBase64/images/icons';
import './SubjectsPopup.scss';

interface SubjectProps {
  /**
   * @description Название предмета
   */
  subject: string;
  /**
   * @description Ссылка на изображение предмета
   */
  subjectImgUrl: string;
  /**
   * @description Текст, описывающий предмет для alt атрибута img
   */
  subjectAltText: string;
  /**
   * @description Предмет приносит прибыль или уменьшает что-то, например жизнь
   */
  isNegative: boolean;
  /**
   * @description Сообщение попапа;
   */
  text: string;
  /**
   * @description Сообщение попапа;
   */
  text1?: string;
  /**
   * @description Есть ли иконка жизней;
   */
  lifeIcon?: boolean;
}

export const Subject = ({ lifeIcon = false, ...props }: SubjectProps) => {
  const { subject, subjectImgUrl = '', subjectAltText, isNegative, text, text1 } = props;

  return (
    <div className={`popup-subject-content ${subject}`}>
      <img className="popup-subject-img" src={subjectImgUrl} alt={subjectAltText} />
      <div className="popup-subject-text">
        {lifeIcon && (
          <div className="info-lives">
            <LifeList activeLivesAmount={2} />
          </div>
        )}
        <Text size="default" text={text} />
        {text1 && <Text size="default" text={text1} />}
      </div>
      <div className={`popup-subject-icon-${isNegative ? 'negative' : 'positive'}`}>
        <img src={isNegative ? negativeSVG : positiveSVG} alt="" />
      </div>
    </div>
  );
};

export const SubjectsPopup = () => {
  return (
    <div className="popup-subject-wrapper">
      <Subject
        subject="case"
        subjectImgUrl={caseSVG}
        subjectAltText="Чемодан"
        isNegative={false}
        text="замедляет скорость"
        text1="может быть промокод"
      />
      <Subject
        subject="desk"
        subjectImgUrl={deskSVG}
        subjectAltText="Доска"
        isNegative={true}
        text="сгорает жизнь"
        lifeIcon={true}
      />
      <Subject
        subject="balloon"
        subjectImgUrl={balloonSVG}
        subjectAltText="Надувной шар"
        isNegative={false}
        text="дополнительные баллы"
      />
      <Subject
        subject="puddle"
        subjectImgUrl={puddleSVG}
        subjectAltText="Лужа"
        isNegative={true}
        text="сгорает жизнь"
        lifeIcon={true}
      />
    </div>
  );
};
