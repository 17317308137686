import { baseApi } from './baseApi';
import { setNickname } from '../store/auth/authSlice';

export const dataTypes = ['user_info', 'referral_codes', 'promocodes', 'leaderboard'] as const;
export type InfoType = UserInfo | ReferralCodeInfo[] | PromoCodeInfo[] | LeaderBoardInfo[];

export type ResultsSection = {
  category: (typeof dataTypes)[number];
  data: InfoType;
};

export type UserInfo = {
  id: number;
  nickname: string;
};

export type ReferralCodeInfo = {
  code: string;
  is_used: boolean;
};

export type PromoCodeInfo = {
  id: number;
  image: string;
  description: string;
  url: string;
  is_used: boolean;
};
export type LeaderBoardInfo = {
  position: number;
  nickname: string;
  id: string;
  score: number;
  is_current_user: boolean;
};

// Define a service using a base URL and expected endpoints
export const resultsApi = baseApi.injectEndpoints({
  endpoints: (builder) => ({
    getResults: builder.query<ResultsSection[], void>({
      query: () => 'rest/user_info',
      async onCacheEntryAdded(arg, { dispatch, cacheDataLoaded, cacheEntryRemoved }) {
        try {
          const { data } = await cacheDataLoaded;
          const userData = data.find((section) => section.category === dataTypes[0])?.data;
          // Вызов dispatch при успешной загрузке кэшированных данных
          dispatch(setNickname({ nickname: (userData as UserInfo)?.nickname }));
        } catch {
          // Обработка ошибок
        }
        await cacheEntryRemoved;
      },
      providesTags: ['Results'],
    }),
  }),
});

export const { useGetResultsQuery, usePrefetch } = resultsApi;
