import { generateRandomString } from '../tools/generateRandom';

const userData = {
  username: 'game_user',
  password: 'L*XjH,eE5S3vj~s',
};

const aeroflotUrlList = {
  signIn: `https://afl-test.test.aeroflot.ru/aac/app/ru-ru/login?nonce=${generateRandomString(64)}&state=${generateRandomString(32)}&redirect_uri=https%3A%2F%2Faero.develop.kokoc.tech%2Fauth_callback&response_type=code%20id_token%20token&client_id=e42ac4e9-405b-49db-8351-3d59074393b7&_preferredLocale=ru&scope=openid+promo-profile&_preferredLanguage=ru`,
  signUp: 'https://www.aeroflot.ru/lk/app/ru-ru/signup/simple',
};

const apiUrl = 'api.aero.develop.kokoc.tech/';

const apiUrlFull = `https://${apiUrl}api/`;

const location = window.location;
const isSecure = location.protocol.includes('https');

const urlList = {
  tokensUrl: `https://${apiUrl}api/auth/token/`,
  uuidUrl: `https://${apiUrl}api/auth/uuid/`,
  socketUrl: `ws${isSecure ? 's' : ''}://${apiUrl}ws/api/game`,
};

export { userData, urlList, apiUrlFull, aeroflotUrlList };
