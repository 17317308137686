import { FC, useState, useEffect, memo } from 'react';
import { Tooltip } from 'react-tooltip';
import { Title } from 'shared/ui/Title/Title';
import { Text } from 'shared/ui/Text/Text';
import { Button } from 'shared/ui/Button/Button';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { Controller, useForm } from 'react-hook-form';
import { Input } from 'shared/ui/Input/Input';
import { RulesModal } from 'shared/ui/RulesModal/RulesModal';
import Modal from 'shared/ui/Modal/Modal';
import { useLazyGetUuidQuery } from '../../shared/redux/services/uuidApi';
import { selectCurrentUuid, setUuid } from '../../shared/redux/store/auth/authSlice';
import { urlList } from '../../shared/config/config';
import { useWebSocket, webSocketMessages, ReadyState } from '../../shared/context/WebSocketContext';
import { useGame } from '../../shared/context/GameContext';
import Loader from '../../shared/ui/Loader/Loader';
import { infoSVG } from '../../shared/resourcesBase64/images/icons';
import { useAuth } from '../../shared/redux/hooks/useAuth';
import './index.scss';

interface ReferalCodeForm {
  referal: string;
}

const InfoBlock = memo(() => (
  <div className="info-wrapper">
    <button data-tooltip-id="info-tooltip" className="info-icon" data-tooltip-variant="light">
      <img src={infoSVG} alt="что это?" />
    </button>
    <Tooltip
      id="info-tooltip"
      className="tooltip"
      opacity={1}
      place={'top-start'}
      classNameArrow="info-arrow"
      offset={15}
    >
      <div>
        Реферальный код — это набор символов, которые вам отправил ваш знакомый или друг. Если у вас
        нет реферального кода, просто возвращайтесь в игру.
      </div>
      <div>
        Если вы захотите поделиться реферальным кодом со своими друзьями, загляните в личный кабинет
        игры.
      </div>
    </Tooltip>
  </div>
));

const Rules: FC = () => {
  const isAuthenticated = useAuth();

  const [trigger, { data, isLoading, isFetching, isError, isUninitialized }] = useLazyGetUuidQuery({
    // pollingInterval: 3000,
    // refetchOnMountOrArgChange: true,
  });
  const uuid = useSelector(selectCurrentUuid);
  const { saveScore, isReferralUsed, saveIsReferralUsed } = useGame();

  const {
    connected,
    sendMessage,
    connectionStatus,
    setWebSocketUrl,
    readyState,
    saveShouldReconnect,
    currentUrl,
  } = useWebSocket();

  const dispatch: Dispatch<UnknownAction> = useDispatch();
  const navigate: NavigateFunction = useNavigate();

  const [isOpenRules, setIsOpenRules] = useState(false);
  const [isOpenReferal, setIsOpenReferal] = useState(false);
  const { control, handleSubmit } = useForm<ReferalCodeForm>();

  const [mounted, setMounted] = useState(false);
  const [triggered, setTriggered] = useState(false);

  useEffect(() => {
    saveShouldReconnect(true);
    setMounted(true);
  }, []);

  useEffect(() => {
    if (
      !mounted ||
      !isAuthenticated ||
      connected ||
      readyState === ReadyState.CONNECTING ||
      currentUrl
    )
      return;

    trigger().then((data) => {
      if (data?.data) {
        dispatch(setUuid(data.data));
        const wsUrl = `${urlList.socketUrl}?uuid=${data.data.uuid}`;
        setTriggered(true);
        setWebSocketUrl(wsUrl);
      }
    });
  }, [mounted, isAuthenticated, connected, readyState, currentUrl]);

  const handleClick: () => void = async () => {
    if ((!connected && !triggered) || !isAuthenticated) return;

    navigate('/game');
  };

  const onSubmit = (data: ReferalCodeForm) => {
    console.log(data);
    if (!data.referal || (!connected && !triggered)) return;

    sendMessage({ action: webSocketMessages.ADD_REFERRAL_CODE, value: data.referal });
    saveScore(300);
    saveIsReferralUsed(true);
    setIsOpenReferal(false);
  };

  return (
    <div className="rules-container">
      <span style={{ position: 'absolute', top: 0, right: 5, fontSize: 15 }}>
        The WebSocket is currently {connectionStatus}
      </span>
      <Title type={'h1'} text="Андрей опаздывает!" />
      <Text
        classNames={['navigation-text']}
        text="Избегайте преград и лужи. Чтобы перепрыгнуть препятствие, нажмите левую кнопку мыши или кликните по экрану смартфона пальцем."
      />
      <Text
        classNames={['navigation-text']}
        text="Собирайте игровые мили и рюкзачки — это хорошие предметы, за которые начисляются баллы или замедляется скорость. Если в рюкзачке спрятан промокод от партнера или «Аэрофлот Бонус», он появится в личном кабинете игры"
      />
      <Button
        {...(!connected && {
          icon: <Loader />,
        })}
        isDisabled={!connected}
        onClick={handleClick}
        classNames={['navigation-button']}
        label={`${!connected ? 'Готовим игру...' : 'Играть!'}`}
      />
      <div className="link-wrapper">
        <button
          className="info-icon-container link"
          onClick={() => setIsOpenReferal((prev) => !prev)}
        >
          У меня есть реферальный код!
        </button>
        <InfoBlock />
      </div>
      <button className="short-rules link" onClick={() => setIsOpenRules(true)}>
        Краткие правила акции и призовой фонд
      </button>
      {mounted && (
        <RulesModal
          isOpen={isOpenRules}
          onClose={() => setIsOpenRules(false)}
          container={document.querySelector('.rules-container') as HTMLElement}
        />
      )}
      {mounted && (
        <Modal
          isOpen={isOpenReferal}
          onClose={() => setIsOpenReferal(false)}
          cls="modal-referral"
          container={document.querySelector('.rules-container') as HTMLElement}
        >
          {isReferralUsed && (
            <small className="referral-code-applied">Вы уже применили код ранее</small>
          )}
          <form className="referral-form" onSubmit={handleSubmit(onSubmit)}>
            <Controller
              control={control}
              name="referal"
              rules={{
                required: 'Введите реферальное слово',
                pattern: {
                  value: /^[a-zA-Z]+$/,
                  message: 'Вы ввели неверный код — попробуйте еще раз.',
                },
              }}
              render={({ field, fieldState }) => (
                <Input
                  placeholder="Введите реферальное слово"
                  dangerText={fieldState.error?.message}
                  isDanger={fieldState.invalid}
                  disabled={isReferralUsed}
                  {...field}
                />
              )}
            />
            <Button isDisabled={isReferralUsed} label="Применить" size="medium" />
            <div className="link-wrapper">
              <div className="info-icon-container pseudo link">Что такое реферальный код!</div>
              <InfoBlock />
            </div>
          </form>
        </Modal>
      )}
    </div>
  );
};

export default Rules;
