import { FC, useState, useEffect, useRef } from 'react';
import * as PIXI from 'pixi.js';
import { Howl, Howler } from 'howler';
import { useMedia } from 'react-use';
import {
  AnimatedSprite,
  Application,
  Assets,
  Container,
  Graphics,
  GraphicsContext,
  Renderer,
  RenderTexture,
  Sprite,
  Texture,
  Rectangle,
  TextureSource,
  // extensions, ResizePlugin,
} from 'pixi.js';
import Matter, {
  Engine,
  World,
  Bodies,
  Body,
  Runner,
  Events,
  Vector,
  Bounds,
  Collision,
  Render,
} from 'matter-js';
import LifeList from 'shared/ui/LifeList/LifeList';
import { Text } from 'shared/ui/Text/Text';
import { urlList } from 'shared/config/config';
import { useNavigate } from 'react-router';
import { generateRandomBoolean } from 'shared/tools/generateRandom';
import { useDispatch } from 'react-redux';
import { setUuid } from '../../shared/redux/store/auth/authSlice';
import { useWebSocket, webSocketMessages } from '../../shared/context/WebSocketContext';
import { useLazyGetUuidQuery } from '../../shared/redux/services/uuidApi';
import { Dispatch, UnknownAction } from '@reduxjs/toolkit';
import { useGame } from '../../shared/context/GameContext';
import { bundles } from '../../shared/resourcesBase64/images/gameAssets';
import { audioActiveSVG, audioInactiveSVG } from '../../shared/resourcesBase64/images/icons';
import {
  gameMP3,
  jumpMP3,
  collisionMP3,
  afterCollisionMP3,
  puddleMP3,
  bonusMP3,
} from '../../shared/resourcesBase64/sounds/sounds';
import Loader from '../../shared/ui/Loader/Loader';
import { useAuth } from '../../shared/redux/hooks/useAuth';
import './index.scss';

// import useDeviceDetection from '../../shared/hooks/useDeviceDetection';

// Define the sounds
const sounds = {
  jumpSound: new Howl({ src: [jumpMP3] }),
  collisionSound: new Howl({ src: [collisionMP3] }),
  afterCollisionSound: new Howl({ src: [afterCollisionMP3] }),
  bonusSound: new Howl({ src: [bonusMP3] }),
  puddleSound: new Howl({ src: [puddleMP3] }),
  backgroundGame: new Howl({
    src: [gameMP3],
    loop: true,
    volume: 0.1, // Set volume to 10%
  }),
};

const ActionType = {
  SCORE_PICKUP: webSocketMessages.SCORE_PICKUP,
  PROMOCODE_PICKUP: webSocketMessages.PROMOCODE_PICKUP,
  DECREASE_LIFE_COUNT: webSocketMessages.DECREASE_LIFE_COUNT,
};

const objectDamageFlags = {
  case: false,
  balloon: false,
  desk: true,
  puddle: true,
};

const objectTypes = ['balloon', 'desk', 'puddle'];

const roadHeightList = {
  desktop: 100,
  tablet: 90,
  mobile: 65,
};

const shadowParamList = {
  case: (isTopPosition: boolean) => {
    if (isTopPosition) return { radiusX: 40, radiusY: 8 };
    return { radiusX: 50, radiusY: 10 };
  },
  balloon: { radiusX: 30, radiusY: 5 },
  desk: { radiusX: 80, radiusY: 17 },
};

class Hero {
  sprite: PIXI.AnimatedSprite;
  body: Body;
  private isImmune: boolean;
  isJumping: boolean;
  isHovering: boolean;
  shadow: PIXI.Graphics;
  shadowFrames: PIXI.GraphicsContext[];
  jumpSound: Howl;
  scale: number;

  constructor(textures: PIXI.Texture[], x: number, y: number, sound: Howl, scale: number) {
    this.sprite = new PIXI.AnimatedSprite(textures);
    this.sprite.animationSpeed = 0.2;
    this.sprite.play();
    this.sprite.anchor.set(0.5);
    this.sprite.x = x;
    this.sprite.y = y;
    this.scale = scale;
    this.sprite.scale = this.scale;

    this.body = Bodies.rectangle(
      x,
      y,
      textures[0].width * this.scale,
      textures[0].height * this.scale,
      {
        label: 'hero',
        friction: 0,
        restitution: 0,
        // area: 0,
        // density: 0,
        // mass: 0,
        // isStatic: true, // the ground doesn't move
        collisionFilter: {
          category: 0x0001,
          mask: 0x0002 | 0x0003, // Allow hero to collide with the ground and objects
        },
      }
    );
    World.add(engine.world, this.body);

    this.setPosition(x, y);
    this.shadowFrames = [];
    this.shadow = this.createShadow(x, y);
    this.isImmune = false;
    this.isJumping = false;
    this.isHovering = false;
    this.jumpSound = sound;
  }

  updatePosition() {
    this.sprite.x = this.body.position.x;
    this.sprite.y = this.body.position.y;
  }

  setPosition(x: number, y: number) {
    this.sprite.x = x;
    this.sprite.y = y;
    Body.setPosition(this.body, { x, y });
  }

  createShadow(x: number, y: number) {
    const shadowBase = new GraphicsContext()
      .ellipse(x, y + this.sprite.height / 2, 100 * this.scale, 20 * this.scale)
      .fill(0xe1e1e1);
    // shadow.pivot.set(visibleWidth / 2, visibleHeight - roadHeightList.desktop / 2);
    // const shadowRun = new GraphicsContext()
    //   .ellipse(visibleWidth / 2, visibleHeight - roadHeightList.desktop / 2 - 10, 80, 15)
    //   .fill(0xe1e1e1);
    const shadowJump = new GraphicsContext()
      .ellipse(
        x,
        y + this.sprite.height / 2 - (this.scale < 1 ? 15 : 20),
        75 * this.scale,
        10 * this.scale
      )
      .fill(0xe4e4e4);
    this.shadowFrames = [shadowBase, shadowJump];
    const shadow = new Graphics(this.shadowFrames[0]);
    return shadow;
  }

  jump() {
    if (this.isJumping) return;

    this.isJumping = true;

    // Play jump sound
    this.jumpSound.play();
    console.log('jumpSound');
    const initialY = this.sprite.y;
    const jumpForce = this.scale < 1 ? -1.1 : -4; // Jump force
    const hangTime = 200; // Hang time at the top (in ms)
    const gravityForce = this.scale < 1 ? 1 : 2; // Gravity force for descent

    // Phase 1: Ascend
    Body.setStatic(this.body, false);
    Body.applyForce(
      this.body,
      { x: this.body.position.x, y: this.body.position.y },
      { x: 0, y: jumpForce }
    );
    this.sprite.gotoAndPlay(2);
    this.shadow.context = this.shadowFrames[1];

    // Wait until reaching the top
    setTimeout(() => {
      // Phase 2: Hang at the top
      Body.setVelocity(this.body, { x: 0, y: 0 });
      Body.setStatic(this.body, true);
      this.sprite.gotoAndPlay(6);

      setTimeout(() => {
        // Phase 3: Descend
        Body.setStatic(this.body, false);
        Body.applyForce(
          this.body,
          { x: this.body.position.x, y: this.body.position.y },
          { x: 0, y: gravityForce }
        );
        this.sprite.gotoAndPlay(8);

        // Phase 4: Complete the jump
        const fallInterval = setInterval(() => {
          const tolerance = 5; // Tolerance value for position check
          if (Math.abs(this.body.position.y - initialY) <= tolerance) {
            Body.setVelocity(this.body, { x: 0, y: 0 });
            Body.setPosition(this.body, { x: this.body.position.x, y: initialY });
            Body.setStatic(this.body, true);
            this.shadow.context = this.shadowFrames[0];
            this.isJumping = false;
            this.jumpSound.stop();
            clearInterval(fallInterval);
          }
        }, 16); // Check every 16 ms (~60 frames per second)
      }, hangTime);
    }, 300); // Time to ascend (300 ms)

    // Фаза подъема
    // Body.setStatic(this.body, false);
    // Body.applyForce(this.body, this.sprite.position, { x: 0, y: -4 });
    // // Body.setVelocity(this.body, { x: 0, y: -15 });
    // // this.sprite.gotoAndPlay(2);
    //
    // setTimeout(() => {
    //
    //   setTimeout(() => {
    //     // Фаза зависания
    //     Body.setStatic(this.body, true);
    //     // Body.setPosition(this.body, { x: this.body.position.x, y: this.body.position.y });
    //     Body.setVelocity(this.body, { x: 0, y: 0 });
    //
    //     setTimeout(() => {
    //       Body.setStatic(this.body, false);
    //       Body.applyForce(this.body, this.sprite.position, { x: 0, y: 1.5 });
    //
    //       setTimeout(() => {
    //         // Фаза спуска
    //         // Body.setVelocity(this.body, { x: 0, y: 15 });
    //         Body.applyForce(this.body, this.sprite.position, { x: 0, y: 4 });
    //
    //         setTimeout(() => {
    //           // Завершение прыжка, возвращение на исходную позицию
    //           Body.setStatic(this.body, true);
    //           Body.setPosition(this.body, { x: this.sprite.position.x, y: initialY });
    //           // this.sprite.gotoAndPlay(7);
    //           // this.sprite.position.y = initialY;
    //           this.isJumping = false;
    //           sound.stop('jumpSound');
    //         }, duration / 2); // Продолжительность спуска
    //       }, duration / 3); // Продолжительность полуспуска
    //     }, hangTime); // Время зависания
    //   }, duration / 4); // Продолжительность подъема
    // }, duration / 2); // Продолжительность полуподьема

    // Body.applyForce(this.body, this.sprite.position as Vector, { x: 0, y: -4 });
    //
    // // Задаем скорость прыжка
    // // Body.setVelocity(this.body, { x: 0, y: -15 });
    // this.sprite.gotoAndPlay(2);
    //
    // // Делаем задержку на верхнем положении
    // setTimeout(() => {
    //   this.isHovering = true;
    //   Body.setPosition(this.body, { x: this.sprite.position.x, y: 0 });
    //   // Body.setVelocity(this.body, { x: 0, y: 0 }); // Останавливаем вертикальное движение
    //   // this.sprite.gotoAndStop(5);
    //
    //   setTimeout(() => {
    //     this.isHovering = false;
    //     // Body.setStatic(this.body, true);
    //     Body.setPosition(this.body, { x: this.sprite.position.x, y: initialY });
    //     // Body.setVelocity(this.body, { x: 0, y: 5 }); // Возвращаем гравитацию
    //     this.sprite.gotoAndPlay(7);
    //     // this.sprite.y = initialY;
    //     this.isJumping = false;
    //   }, 1500); // Длительность задержки в верхнем положении
    // }, 300); // Время, после которого герой "зависает" на верхней точке
  }

  triggerImmunity() {
    this.isImmune = true;
    let blinkCount = 0;

    const blinkInterval = setInterval(() => {
      this.sprite.visible = !this.sprite.visible;
      blinkCount++;
      if (blinkCount >= 6) {
        clearInterval(blinkInterval);
        this.sprite.visible = true;
        setTimeout(() => {
          this.isImmune = false;
        }, 0);
      }
    }, 100);
  }

  get immune() {
    return this.isImmune;
  }
}

class GameObject {
  sprite: PIXI.Sprite;
  body: Body;
  label: string;
  isColliding: boolean;
  shadow: PIXI.Graphics | null;
  shadowBody: Body | null;
  scale: number;
  shadowParams:
    | { isCasePositionBottom: boolean; y: number; radiusX: number; radiusY: number }
    | undefined;

  constructor(
    texture: PIXI.Texture,
    x: number,
    y: number,
    label: string,
    scale: number,
    shadow:
      | { isCasePositionBottom: boolean; y: number; radiusX: number; radiusY: number }
      | undefined
  ) {
    this.sprite = new PIXI.Sprite(texture);
    this.sprite.x = x;
    this.sprite.y = y;
    console.log('x ', x, ' y ', y);
    this.scale = scale;
    this.body = Bodies.rectangle(x, y, texture.width * this.scale, texture.height * this.scale, {
      label,
      friction: 0,
      isSensor: true,
      collisionFilter: {
        category: 0x0002,
        mask: 0x0001, // Allow objects to collide with the hero
      },
    });
    this.label = label;
    this.sprite.scale = this.scale;
    this.shadowParams = shadow;
    this.isColliding = false;
    this.shadow = this.createShadow(x);
    this.shadowBody = this.createShadowBody(x);
    World.add(engine.world, this.body);

    Body.setPosition(this.body, { x, y });
  }

  updatePosition() {
    // this.body.position.x = this.sprite.x;
    // this.body.position.y = this.sprite.y;
    this.sprite.x = this.body.position.x;
    if (this.shadow) {
      // this.shadow.x = this.body.position.x;
      // console.log('this.body.position.x ', this.body.position.x);
      // console.log('this.shadow.x ', this.shadow.x);
    }
    // this.sprite.y = this.body.position.y;
  }

  createShadow(x: number) {
    // console.log('this.shadowParams ', this.shadowParams);

    if (!this.shadowParams) return null;

    const { y, radiusX, radiusY } = this.shadowParams;
    return new Graphics().ellipse(x, y, radiusX, radiusY).fill(0xe1e1e1);
  }

  createShadowBody(x: number) {
    if (!this.shadowParams) return null;

    const { y, radiusX, radiusY } = this.shadowParams;
    const shadowBody = Bodies.rectangle(x, y, radiusX, radiusY, {
      label: `${this.label}-shadow`,
      friction: 0,
      isSensor: true,
      collisionFilter: {
        category: 0x0004,
        // mask: 0x0002, // Allow objects to collide with the hero
      },
    });
    // World.add(engine.world, shadowBody);

    // Body.setPosition(shadowBody, { x, y });
    return shadowBody;
  }

  moveLeft(deltaX: number) {
    Body.setVelocity(this.body, { x: deltaX, y: 0 });
    if (this.shadow) {
      // this.shadow.x += deltaX;
      // Body.setVelocity(this.shadowBody, { x: deltaX, y: 0 });
    }
    // Body.translate(this.body as Body, { x: deltaX, y: this.body.position.y });
    this.updatePosition();
  }

  remove() {
    World.remove(engine.world, this.body);
    this.sprite.destroy();
    if (this.shadow) {
      this.shadow.destroy();
      // World.remove(engine.world, this.shadowBody);
    }
  }
}

let engine: Engine;
let runner: Runner;

const Game: FC = () => {
  const isAuthenticated = useAuth();

  const { saveIsFirstGame, isAudioMuted, saveIsAudioMuted, saveScore, savePromoCodes } = useGame();
  const [audio, setAudio] = useState(!isAudioMuted);

  const {
    connected,
    messages,
    lastMessage,
    error,
    sendMessage,
    setWebSocketUrl,
    currentUrl,
    connectionStatus,
    saveShouldReconnect,
  } = useWebSocket();

  const [trigger, { data, isLoading, isFetching, isError, isUninitialized }] = useLazyGetUuidQuery({
    // pollingInterval: 3000,
    // refetchOnMountOrArgChange: true,
  });
  const dispatch: Dispatch<UnknownAction> = useDispatch();

  const isDesktop = useMedia('(min-width: 1080px)');
  const isTablet = useMedia('(min-width: 480px)');
  const isMobile = !(isTablet || isDesktop);

  const [messageHistory, setMessageHistory] = useState<MessageEvent<any>[]>([]);

  const navigate = useNavigate();

  const [mounted, setMounted] = useState(false);
  const [isGameLoading, setIsGameLoading] = useState(false);
  const [isOrientationCorrect, setIsOrientationCorrect] = useState<boolean | null>(null);
  const [isGameOver, setIsGameOver] = useState(false);

  const [score, setScore] = useState(0);
  const [lives, setLives] = useState(3);
  const appRef = useRef<PIXI.Application>();
  const heroRef = useRef<Hero>();
  const objectsRef = useRef<GameObject[]>([]); // счетчик кол-ва выпавших предметов
  const objectsVelocity = useRef({ x: -4, y: 0 }); // скорость смещения предметов справа налево
  const backgroundVelocity = useRef(0.0025); // скорость смещения панорамы города справа налево
  const spawnCountRef = useRef(10); // выпадает 10 предметов за 20 секунд
  const currentSpawnIntervalRef = useRef(20000); // 20 секунд
  const baseCountRef = useRef(1); // 1 раунд
  const damageCountRef = useRef(6); // 6 предметов урона в сумме (доска и лужа)
  const scoreCountRef = useRef(3); // 3 предмета, приносящих очки (надувной шарик)
  const scaleRef = useRef(isMobile ? 0.6 : 1);
  const backScaleRef = useRef(isMobile ? 0.89 : isTablet ? 0.95 : 1);

  const getRoadHeight = () => {
    if (isDesktop) return roadHeightList.desktop;
    if (isTablet) return roadHeightList.tablet;
    return roadHeightList.mobile;
  };
  const roadHeightRef = useRef(getRoadHeight());

  // Счетчики типов объектов
  const objectCountsRef = useRef({
    case: 0,
    balloon: 0,
    desk: 0,
    puddle: 0,
  });

  useEffect(() => {
    setMounted(true);
  }, []);

  // useEffect(() => {
  //   if (!mounted || !isAuthenticated || connected || currentUrl) return;
  //   console.log('trigger: isAuthenticated ', isAuthenticated, ' currentUrl ', currentUrl);
  //
  //   trigger().then((data) => {
  //     if (data?.data) {
  //       dispatch(setUuid(data.data));
  //       const wsUrl = `${urlList.socketUrl}?uuid=${data.data.uuid}`;
  //       setWebSocketUrl(wsUrl);
  //     }
  //   });
  // }, [mounted, currentUrl, isAuthenticated]);

  useEffect(() => {
    console.log('messages ', messages);
    // const { life_count, score: newScore } = msg || {};
    // console.log('life_count ', life_count, ' score ', newScore);

    // setScore(String(newScore));
    // setLives(Number(life_count));
    // setMessageHistory((prev) => prev.concat(message));
  }, [messages]);

  useEffect(() => {
    if (!lastMessage) return;
    const { life_count, score: newScore, promocodes = [] } = lastMessage.message || {};
    console.log('life_count ', life_count);
    console.log('promocodes ', promocodes);
    if (promocodes.length) savePromoCodes([...promocodes]);
    // setScore(newScore);
    // setLives(Number(life_count));
    setMessageHistory((prev) => prev.concat(lastMessage.message));
  }, [lastMessage]);

  useEffect(() => {
    if (lives) return;

    saveIsFirstGame(false);
    saveScore(score);
    console.log('life_count === 0 ', score);
  }, [lives]);

  // const device = useDeviceDetection();

  const initGame = async () => {
    if (appRef.current || !isAuthenticated) return;

    setIsGameLoading(true);

    console.log('isMobile ', isMobile);

    // if (isMobile.tablet) {
    //   // The device is an Apple tablet device.
    // }

    // gsap.registerPlugin(PixiPlugin);
    // PixiPlugin.registerPIXI(PIXI);

    const gameContainer = document.querySelector('.game-scene') as HTMLElement;
    gameContainer.replaceChildren(); //

    // extensions.add(ResizePlugin);

    appRef.current = new Application();
    // Initialize the application
    await appRef.current?.init({
      // autoStart: false,
      resizeTo: gameContainer, // This line here handles the actual resize!
      resolution: window.devicePixelRatio || 1,
      autoDensity: true,
      backgroundColor: '#EAF2FB',
      antialias: false,
    });

    gameContainer?.appendChild(appRef.current.canvas);

    // ---- добавить физику ---
    // Создаем движок Matter.js
    engine = Engine.create();
    runner = Runner.create();
    Runner.run(runner, engine);
    const world = engine.world;

    // const render = Render.create({
    //   element: document.body,
    //   engine: engine,
    //   options: {
    //     width: 800,
    //     height: 600,
    //     wireframes: false, // Установите false для отображения текстур
    //     showAngleIndicator: true, // Показывает индикаторы углов
    //     showVelocity: true, // Показывает скорости тел
    //     showCollisions: true, // Показывает столкновения
    //     showBounds: true, // Показывает границы тел
    //     showAxes: true, // Показывает оси тел
    //     showPositions: true, // Показывает позиции тел
    //   },
    // });
    // Render.run(render);
    // Render.lookAt(render, {
    //   min: { x: 0, y: 0 },
    //   max: { x: 800, y: 600 },
    // });

    // Устанавливаем гравитацию
    engine.gravity.y = 1;

    // const physics = Matter.Engine.create();
    let loadAssets: any = {};
    for await (const bundle of bundles) {
      const { name, assets } = bundle;
      let aliasList: string[] = [];
      let frames: any = {};
      for await (const { alias, data } of assets) {
        Assets.add({
          alias,
          src: data,
          // data: {
          //   parseAsGraphicsContext: true,
          // },
        });
        aliasList = [...aliasList, alias];
        const texture = await Assets.load(alias);
        frames = { ...frames, [alias]: texture };
      }
      loadAssets = { ...loadAssets, [name]: frames };
    }

    const cityTextures = loadAssets.back;
    // const cityTextures = await Assets.loadBundle('back'); // => Promise<{flowerTop: Texture, eggHead: Texture}>
    // When the promise resolves, we have the texture!
    console.log('cityTextures ', cityTextures);

    const visibleWidth = appRef.current.screen.width;
    const visibleHeight = appRef.current.screen.height;
    const targetHeight = visibleHeight - roadHeightRef.current;

    const cityScale = (targetHeight / cityTextures[`city_bg_01`].height) * backScaleRef.current;

    const cityContainer = new Container({
      // this will make moving this container GPU powered
      isRenderGroup: true,
    });

    const cityContainer1 = new Container({
      // this will make moving this container GPU powered
      isRenderGroup: true,
    });

    const cityAssetAmount = 13;
    for (let i = 1; i <= cityAssetAmount; i++) {
      const texture = cityTextures[`city_bg_${String(i).length > 1 ? i : '0' + i}`];
      const tree = new Sprite({
        texture,
        x: texture.width * i * cityScale,
        y: targetHeight,
        scale: cityScale,
        anchor: { x: 1, y: 1 },
      });
      const tree1 = new Sprite({
        texture,
        x: texture.width * i * cityScale,
        y: targetHeight,
        scale: cityScale,
        anchor: { x: 1, y: 1 },
      });

      cityContainer.addChild(tree);
      cityContainer1.addChild(tree1);
    }

    // sort the trees by their y position
    // cityContainer.children.sort((a, b) => a.position.y - b.position.y);

    // console.log('cityContainer ', cityContainer);
    // console.log('cityContainer.width ', cityContainer.width);
    const cityWidth = cityContainer.width;

    const visibleCity = new Rectangle(0, targetHeight, visibleWidth, targetHeight);
    cityContainer.cullArea = visibleCity;
    // cityContainer.pivot.set(0, 1);
    cityContainer1.cullArea = visibleCity;
    cityContainer1.x = cityWidth;

    setIsGameLoading(false);

    appRef.current?.stage.addChild(cityContainer);
    appRef.current?.stage.addChild(cityContainer1);

    const roadSprite = new Graphics()
      .rect(0, targetHeight, visibleWidth, roadHeightRef.current + 100)
      .fill(0xebebeb);

    appRef.current?.stage.addChild(roadSprite);

    sounds.backgroundGame.play();

    // // Create an AnimatedSprite
    // const cityFrames: Texture[] = [];
    // for (let i = 1; i <= cityAssetAmount; i++) {
    //   console.log('cityTextures[i] ', cityTextures[`city_bg_${i}`]);
    //
    //   cityFrames.push(cityTextures[`city_bg_${i}`]);
    // }
    // const cityAnim = new AnimatedSprite(cityFrames);
    //
    // console.log('cityAnim ', cityAnim);
    //
    // cityAnim.anchor.set(0.5, 1);
    // cityAnim.x = visibleWidth / 2;
    // cityAnim.y = visibleHeight - roadHeightList.desktop / 2;
    // cityAnim.scale.set(cityScale);
    // cityAnim.animationSpeed = 0.02 * currentAcceleration;
    // cityAnim.play();

    // Assets.add({
    //   alias: 'cityBg',
    //   src: `../images/game/backs/city_bg.${device === 'Mobile' ? 'svg' : 'png'}`,
    //   // crossOrigin: 'anonymous',
    //   // data: {
    //   //   parseAsGraphicsContext: true,
    //   // },
    // });
    // await Assets.backgroundLoad('cityBg');
    // const citySvg = await Assets.load('cityBg');
    //
    // console.log('citySvg ', citySvg); //

    // const renderTexture2 = RenderTexture.create({
    //   width: cityContainer.width,
    //   height: targetHeight,
    // });
    //
    // appRef.current.renderer.render({
    //   container: cityContainer,
    //   target: renderTexture2,
    //   clear: false,
    // });
    //
    // const tilingSprite = new TilingSprite({
    //   texture: renderTexture2,
    //   width: cityContainer.width,
    //   height: targetHeight,
    //   // tileScale: { x: cityScale, y: cityScale },
    // });

    // console.log('tilingSprite ', tilingSprite);

    // tilingSprite.tilePosition.x = 0;
    // tilingSprite.tilePosition.y = 0;
    // tilingSprite.anchor.set(0);

    // Add the hero assets to load
    const heroAssetAmount = 14;
    // Load the assets and get a resolved promise once both are loaded
    const heroTextures = loadAssets.hero;
    // const heroTextures = await Assets.loadBundle('hero'); // => Promise<{flowerTop: Texture, eggHead: Texture}>
    // When the promise resolves, we have the texture!
    console.log('heroTextures ', heroTextures);

    // Создание текстуры из SVG данных
    // Create an AnimatedSprite
    const frames: Texture[] = [];

    for (let i = 1; i <= heroAssetAmount; i++) {
      // const ts = new TextureSource({
      //   width: heroTextures[i].width * scale,
      //   height: heroTextures[i].height * scale,
      //   resource: heroTextures[i],
      // });
      // console.log('ts ', ts);
      //
      // const t = new Texture(ts);
      // console.log('t ', t);

      // heroTextures[i].frame.width = heroTextures[i].frame.width * scale;
      // heroTextures[i].frame.height = heroTextures[i].frame.height * scale;
      // heroTextures[i].source.resize(
      //   heroTextures[i].frame.width * scale,
      //   heroTextures[i].frame.height * scale
      // );

      // heroTextures[i].source.width = heroTextures[i].frame.width * scale;
      // heroTextures[i].source.height = heroTextures[i].frame.height * scale;

      // heroTextures[i].update();

      frames.push(heroTextures[`${String(i).length > 1 ? i : '0' + i}`]);
    }

    heroRef.current = new Hero(
      frames,
      window.innerWidth / 2,
      visibleHeight - roadHeightRef.current / 2 - (frames[0].height / 2) * scaleRef.current,
      sounds.jumpSound,
      scaleRef.current
    );

    const groundSprite = new Graphics()
      .rect(-visibleWidth / 2, -roadHeightRef.current / 4, visibleWidth, roadHeightRef.current / 2)
      .fill(0xebebeb);

    // Создаем "землю", чтобы объект A стоял на месте
    const ground = Bodies.rectangle(
      visibleWidth / 2,
      visibleHeight - roadHeightRef.current / 4,
      visibleWidth,
      roadHeightRef.current / 2,
      {
        isStatic: true, // the ground doesn't move
        label: 'ground',
        collisionFilter: {
          category: 0x0003,
          mask: 0x0001,
        },
      }
    );

    // const shadowAnimation = new AnimatedSprite([shadowBaseTexture, shadowJumpTexture]);
    // shadowAnimation.animationSpeed = 0.2;
    // shadowAnimation.play();
    // line it up as this svg is not centered
    // const bounds = shadow.getLocalBounds();

    // shadow.pivot.set((bounds.x + bounds.width) / 2, (bounds.y + bounds.height) / 2);

    // const shadowContextBigger = new GraphicsContext()
    //   .ellipse(visibleWidth / 2, visibleHeight - roadHeightList.desktop / 2, 100, 20)
    //   .fill(0xe1e1e1);
    //
    // const shadowContextSmaller = new GraphicsContext()
    //   .ellipse(visibleWidth / 2, visibleHeight - roadHeightList.desktop / 2, 80, 15)
    //   .fill(0xe1e1e1);
    //
    // const shadowFrames = [shadowContextBigger, shadowContextSmaller];
    // let frameIndex = 0;
    //
    // const graphics = new Graphics(shadowFrames[frameIndex]);

    // let doShadowSmaller = true;

    // const shadowFrames1 = [shadowBase, shadowRun];

    // let frameIndex = 0;

    // let currentCitySection: number = 0;
    // Listen for animate update
    // console.log('cityContainer.width ', cityContainer.width);
    // console.log('cityContainer.x ', cityContainer.x);

    // let moved = false;
    // cityContainer.onRender = () => {
    //   // console.log('cityContainer.pivot.x ', cityContainer.pivot.x);
    //   // app.update();
    // };

    let isCLicked = false;
    const onClick = () => {
      if (isCLicked) return;

      isCLicked = true;
      // Проверяем, на земле ли персонаж (например, проверяем вертикальную скорость)
      if (heroRef.current?.body.velocity.y === 0) {
        // Matter.Body.applyForce(animBody, anim.position, { x: 0, y: -jumpHeight });
      }

      if (heroRef.current?.body.position.y ?? 0 >= visibleHeight - roadHeightRef.current / 2) {
        // Проверяем, что объект находится на земле
        // Body.setVelocity(animBody, { x: 0, y: -10 }); // Устанавливаем вертикальную скорость для прыжка
      }

      if (Math.abs(heroRef.current?.body.velocity.y ?? 0) < 0.1) {
        // Проверяем, что объект находится на земле
        // Body.setVelocity(heroRef.current?.body, { x: 0, y: -10 }); // Устанавливаем вертикальную скорость для прыжка
        Body.applyForce(heroRef.current?.body as Body, heroRef.current?.sprite.position as Vector, {
          x: 0,
          y: -10,
        });
        heroRef.current?.sprite.gotoAndPlay(2);

        setTimeout(() => {
          heroRef.current?.sprite.gotoAndPlay(7);
          isCLicked = false;
          // shadow.rotation += jumpHeight / 6;
          // shadow.x = visibleWidth / 2;
          // shadow.scale.set(1);
          // shadow.position.set(
          //   visibleWidth / 2,
          //   visibleHeight - roadHeightList.desktop / 2 + jumpHeight / 4
          // );
        }, 400);
      }
    };
    // Pointers normalize touch and mouse (good for mobile and desktop)
    // hitAria.on('pointerdown', onClick);

    // Matter.Events.on(physics, 'collisionStart', onCollisionStart.bind(app));

    // app.stage.addChild(tilingSprite);
    appRef.current.stage.addChild(heroRef.current.shadow);
    appRef.current.stage.addChild(heroRef.current.sprite);

    // appRef.current.stage.addChild(groundSprite);
    World.add(engine.world, ground);

    // ---- загрузка ассетов выпадающих предметов ----
    const subjectsTextures = loadAssets.objects;

    // Функция для равномерного распределения спавна объектов
    let startSpawnTimer: NodeJS.Timeout;
    let previousSpawnedObjectType: keyof typeof objectCountsRef.current | null = null;

    const startSpawningObjects = () => {
      const gameContainer = document.querySelector('.game-scene') as HTMLElement;

      if (!gameContainer || !appRef.current || isGameOver) {
        clearTimeout(startSpawnTimer);
        return;
      }

      const spawnInterval = currentSpawnIntervalRef.current / spawnCountRef.current;
      let spawnedObjects = 0;

      let spawnTimer: NodeJS.Timeout;
      const spawnObject = () => {
        const gameContainer = document.querySelector('.game-scene') as HTMLElement;

        if (
          spawnedObjects >= spawnCountRef.current ||
          !gameContainer ||
          !appRef.current ||
          isGameOver
        ) {
          clearTimeout(spawnTimer);
          return;
        }

        // Находим типы с наибольшим количеством предметов
        const maxTypes = Object.keys(objectCountsRef.current).reduce(
          (arr: string[], currentType) => {
            const value =
              objectCountsRef.current[currentType as keyof typeof objectCountsRef.current];
            if (!arr.length && value) return [currentType];
            if (value > objectCountsRef.current[arr[0] as keyof typeof objectCountsRef.current])
              return [currentType];
            if (value === objectCountsRef.current[arr[0] as keyof typeof objectCountsRef.current])
              return [...arr, currentType];
            return [...arr];
          },
          []
        );
        console.log('maxTypes ', maxTypes);

        const damageObjects = objectCountsRef.current['desk'] + objectCountsRef.current['puddle'];
        const scoreObject = objectCountsRef.current['balloon'];
        const fraction = damageObjects / (scoreObject || 1);
        let nextObjectIsDamage = false;
        if (fraction <= damageCountRef.current / scoreCountRef.current && scoreObject)
          nextObjectIsDamage = true;

        // Список доступных для выпадения типов
        let availableTypes = Object.keys(objectCountsRef.current).filter((type) => {
          const value = objectCountsRef.current[type as keyof typeof objectCountsRef.current];

          console.log('type ', type, ' value ', value);
          if (!value) return true;
          if (type === 'case') return !value;

          return (
            !maxTypes.find((t) => t === type) &&
            nextObjectIsDamage ===
              Boolean(objectDamageFlags[type as keyof typeof objectDamageFlags])
          );
        });
        if (!availableTypes.length) availableTypes = objectTypes;
        availableTypes = availableTypes.filter((t) => t !== previousSpawnedObjectType);
        console.log(
          'availableTypes ',
          availableTypes,
          ' previousSpawnedObjectType ',
          previousSpawnedObjectType
        );

        const randomType = availableTypes[
          Math.floor(Math.random() * availableTypes.length)
        ] as keyof typeof objectCountsRef.current;
        previousSpawnedObjectType = randomType;

        const texture = subjectsTextures[randomType];
        console.log('randomType ', randomType);

        const isCasePositionBottom = !!generateRandomBoolean() && randomType === 'case';
        const yPositionList = {
          case:
            visibleHeight -
            roadHeightRef.current / 2 -
            (isCasePositionBottom
              ? 0
              : heroRef.current?.sprite.height
                ? heroRef.current?.sprite.height * 1.3
                : 0),
          balloon:
            visibleHeight -
            roadHeightRef.current / 2 -
            (heroRef.current?.sprite.height ? heroRef.current?.sprite.height * 1.3 : 0),
          desk: visibleHeight - roadHeightRef.current / 3,
          puddle: visibleHeight - roadHeightRef.current / 4,
        };

        const params = shadowParamList[randomType as keyof typeof shadowParamList];
        let shadow;
        if (params) {
          let shadowParams;
          if (Object.prototype.toString.call(params) == '[object Function]') {
            shadowParams = (
              params as unknown as (isCasePositionTop: boolean) => {
                radiusX: number;
                radiusY: number;
              }
            )(isCasePositionBottom);
          } else shadowParams = params;

          const { radiusX, radiusY } = shadowParams as {
            radiusX: number;
            radiusY: number;
          };

          shadow = {
            isCasePositionBottom: isCasePositionBottom,
            y: visibleHeight - roadHeightRef.current / 2,
            radiusX,
            radiusY,
          };
        }

        const obj = new GameObject(
          texture,
          visibleWidth,
          yPositionList[randomType] - subjectsTextures[randomType].height * scaleRef.current,
          randomType,
          scaleRef.current,
          shadow
        );
        // if (obj.shadow) appRef.current?.stage.addChild(obj.shadow);
        appRef.current?.stage.addChild(obj.sprite);
        objectsRef.current.push(obj);
        objectCountsRef.current[randomType]++;
        console.log('objectCountsRef.current ', objectCountsRef.current);

        console.log(engine.world.bodies);
        console.log(objectsRef.current);
        spawnedObjects++;
      };

      for (let i = 0; i < spawnCountRef.current; i++) {
        const baseTime = i * spawnInterval;
        const randomOffset = (Math.random() - 0.5) * spawnInterval; // Смещение от -spawnInterval/2 до +spawnInterval/2
        const delay = baseTime + randomOffset;
        if (spawnedObjects >= spawnCountRef.current) return;

        setTimeout(() => {
          spawnObject();
        }, delay);
      }

      startSpawnTimer = setTimeout(() => {
        startSpawningObjects();
        if (!dropVelocityTimer) {
          objectsVelocity.current.x *= 1.1; // Увеличиваем скорость смещения предметов на 10%
          backgroundVelocity.current *= 1.1; // Увеличиваем скорость смещения города на 10%
        }
        spawnCountRef.current += 2; // Увеличиваем количество объектов каждые 20 секунд на 2
        damageCountRef.current++; // Увеличиваем долю предметов урона на 1 единицу
        scoreCountRef.current++; // Увеличиваем долю предметов увеличения счета на 1 единицу
        baseCountRef.current++; // Следующий раунд
        if (heroRef.current) {
          heroRef.current.sprite.animationSpeed *= 1.1; // Увеличиваем скорость бега героя на 10%
        }

        Object.keys(objectCountsRef.current).forEach(
          (type) => (objectCountsRef.current[type as keyof typeof objectCountsRef.current] = 0)
        );
        sendMessage({ action: webSocketMessages.SPEED_UP, value: '' });
      }, currentSpawnIntervalRef.current);
    };

    const everySecTimer = setInterval(() => {
      setScore((prev) => prev + 10 * baseCountRef.current);
    }, 1000);

    // ---- выпадение предметов ----
    // const subjectOnStageList: Sprite[] = [];
    // const getRandomIndex = () =>
    //   generateRandomInteger({ min: 1, max: subjectsAssetAliasList.length });

    // const generateSubject = () => {
    //   let subjectToAddIndex = getRandomIndex();
    //   // const subjectToAdd = subjectSpriteList[subjectToAddIndex];
    //   // subjectToAdd.x = visibleWidth;
    //   // subjectToAdd.y = targetHeight;
    //   console.log('subjectToAddIndex ', subjectToAddIndex);
    //   const maxParticles = ['cases', 'balloons', 'desks', 'puddles'].reduce(
    //     ({ type, amount }: { type: number | null; amount: number | null }, subject, index) => {
    //       // if (amount === null || amount <= subject.particles.length)
    //       //   return { type: index + 1, amount: subject.particles.length };
    //       return { type, amount };
    //     },
    //     { type: null, amount: null }
    //   );
    //   const maxArray = ['cases', 'balloons', 'desks', 'puddles'].reduce((arr: number[], subject, index) => {
    //     // return maxParticles.amount === subject.particles.length ? [...arr, index + 1] : arr;
    //   }, []);
    //   console.log('minParticles ', maxParticles, maxArray);
    //
    //   while (
    //     maxArray?.find((i) => i === subjectToAddIndex) &&
    //     maxArray.length < subjectsAssetAliasList.length
    //   ) {
    //     subjectToAddIndex = getRandomIndex();
    //     console.log('while - subjectToAddIndex ', subjectToAddIndex);
    //   }
    // };

    // A basic AABB check between two different squares
    const testForAABB = (subject: Sprite, hero: AnimatedSprite) => {
      const boundsSubject = subject.getBounds();
      const boundsHero = hero.getBounds();

      let { x: xH, y: yH, width: widthH, height: heightH } = boundsHero;
      xH += widthH / 4;
      yH += 0;
      widthH -= widthH / 2;
      heightH -= 0;

      const { x: xS, y: yS, width: widthS, height: heightS } = boundsSubject;
      // xS += widthS / 5;
      // yS += heightS / 5;
      // widthS -= (widthS / 5) * 3;
      // heightS -= (heightS / 5) * 3;

      return xS < xH + widthH && xS + widthS > xH && yS < yH + heightH && yS + heightS > yH;
    };

    let dropVelocityTimer: NodeJS.Timeout;

    const handleCollision = (object: GameObject) => {
      if (heroRef.current?.immune || isGameOver) return;

      const { body: otherBody } = object;
      console.log('handleCollision object  - ', otherBody.id, object.sprite.uid);

      let action;
      let currentSound = sounds.collisionSound;
      let isDamage = false;

      if (otherBody.label === 'desk' || otherBody.label === 'puddle') {
        console.log('handleCollision ', otherBody.label);

        setLives((prevLives) => {
          const newLives = prevLives - 1;
          if (newLives <= 0) {
            console.log('Game Over');
            setIsGameOver(true);
            cleanUp();
          }
          return newLives;
        });

        if (otherBody.label === 'puddle') {
          currentSound = sounds.puddleSound;
        }

        isDamage = true;

        heroRef.current?.triggerImmunity();
        action = ActionType.DECREASE_LIFE_COUNT;
      } else if (otherBody.label === 'balloon') {
        console.log('handleCollision ', otherBody.label);
        currentSound = sounds.bonusSound;

        setScore((prevScore) => prevScore + 50 * baseCountRef.current);
        action = ActionType.SCORE_PICKUP;
      } else if (otherBody.label === 'case') {
        console.log('handleCollision ', otherBody.label);
        currentSound = sounds.bonusSound;

        // при подъеме рюкзака скорость игры пападет на 10% на 10 секунд
        objectsVelocity.current.x *= 0.9;
        backgroundVelocity.current *= 0.9;
        if (heroRef.current) {
          heroRef.current.sprite.animationSpeed *= 0.9;
        }

        dropVelocityTimer = setTimeout(() => {
          // восстанавливаем скорость игры по истчении 10 секунд
          objectsVelocity.current.x *= 1.1;
          backgroundVelocity.current *= 1.1;
          if (heroRef.current) {
            heroRef.current.sprite.animationSpeed *= 1.1;
          }
          clearTimeout(dropVelocityTimer);
        }, currentSpawnIntervalRef.current / 2);

        action = ActionType.PROMOCODE_PICKUP;
      }

      currentSound.play();

      sendMessage({ action, value: '' });

      // Увеличение масштаба и вращение объекта
      // Body.scale(otherBody, 1.5, 1.5);
      // Body.rotate(otherBody, Math.PI / 4);

      // Увеличение силы гравитации для имитации падения после столкновения
      Body.applyForce(
        otherBody,
        { x: object.sprite.position.x, y: object.sprite.position.y },
        { x: 0, y: 3 }
      );

      setTimeout(() => {
        // Возвращаем тело в нормальное состояние и удаляем объект
        // Body.scale(otherBody, 1 / 1.5, 1 / 1.5);

        // const objIndex = objectsBRef.current.findIndex((obj) => obj.body === otherBody);
        // if (objIndex > -1) {
        //   const removedObj = objectsBRef.current[objIndex];
        object.remove();
        // objectsBRef.current.splice(objIndex, 1);
        objectsRef.current = objectsRef.current.filter((o) => o !== object);

        if (!isDamage) return;

        setTimeout(() => {
          sounds.afterCollisionSound.play();
        }, 200);
        // }
      }, 300); // Удаление объекта через 300 мс
    };

    appRef.current.stage.interactive = true;
    appRef.current.stage.hitArea = new PIXI.Rectangle(
      0,
      0,
      appRef.current.screen.width,
      appRef.current.screen.height
    );
    appRef.current.stage.cursor = 'pointer';
    appRef.current.stage.on('pointerdown', () => {
      if (!heroRef.current?.isJumping) {
        heroRef.current?.jump();
      }
    });

    const onCollisionStart = (event: { pairs: any }) => {
      const gameContainer = document.querySelector('.game-scene') as HTMLElement;
      console.log('onCollisionStart');

      if (!gameContainer || !appRef.current || isGameOver) return;

      const pairs = event.pairs;
      console.log('onCollisionStart - pairs ', pairs);

      pairs.forEach((pair: { bodyA: any; bodyB: any }) => {
        const { bodyA, bodyB } = pair;
        if (
          (bodyA.label === 'hero' || bodyB.label === 'hero') &&
          bodyA.label !== 'ground' &&
          bodyB.label !== 'ground'
        ) {
          const otherBody = bodyA.label === 'hero' ? bodyB : bodyA;
          const heroBody = bodyA.label === 'hero' ? bodyA : bodyB;
          console.log('otherBody ', otherBody.bounds, otherBody.position);
          console.log('heroBody ', heroBody.bounds, heroBody.position);

          const overlaps = Bounds.overlaps(heroBody.bounds, otherBody.bounds);
          console.log('overlaps ', overlaps);

          if (overlaps) {
            // narrow phase
            const collision = Collision.collides(bodyA, bodyB);
            console.log('collision ', collision);

            if (collision?.collided) {
              handleCollision(otherBody);
            }
          }
        }
      });
    };

    const update = () => {
      // appRef.current.ticker.add(() => {
      if (isGameOver) return;

      // Engine.update(engine);

      // * use delta to create frame-independent transform *
      // console.log('cityContainer.pivot.x ', cityContainer.pivot.x);
      // if (cityContainer.width - cityContainer.pivot.x <= visibleWidth) {
      //   console.log('перемещаем кадр города');
      //   const citySection = cityContainer.removeChildAt(0);
      //   cityContainer.addChild(citySection);
      //   cityContainer.updateTransform();
      // }
      // cityContainer.pivot.x += visibleWidth * 0.0025 * currentAcceleration;
      // tilingSprite.tilePosition.x -= visibleWidth * 0.005 * currentAcceleration;
      // console.log('next tick сдвигаем город ', tilingSprite.tilePosition.x);
      // shadow.scale.set(0.7);
      // time.deltaTime
      // shadow.y -= 1 * time.deltaTime;
      // swap the context - this is a very cheap operation!
      // much cheaper than clearing it each frame.
      // shadow.context = shadowFrames1[frameIndex++ % shadowFrames.length];
      // shadow.
      // console.log('cityContainer.width ', cityContainer.width);
      // console.log('cityContainer.x ', cityContainer.x, cityWidth);
      // doShadowSmaller = !doShadowSmaller;
      if (cityContainer.x + cityContainer.width <= 0) {
        // console.log('перемещаем город');
        // console.log('before cityContainer.x ', cityContainer.x);
        cityContainer.x = cityContainer1.x + cityContainer.width;
        // console.log('after cityContainer.x ', cityContainer.x);
        // console.log(' before remove cityContainer ', cityContainer);
        // const citySection = cityContainer.getChildAt(currentCitySection);
        // const clone = new Sprite(citySection);
        // cityContainer.addChildAt(clone, 14 + currentCitySection);
        // currentCitySection++;
        // console.log(' after add cityContainer ', cityContainer);
        // cityContainer.width += cityTextures[`city_bg_1`].width;
        // console.log('cityContainer.width ', cityContainer.width);
        // moved = true;
        // return;
        // app.stage.removeChild(cityContainer);
        // app.stage.addChild(cityContainer);
        // cityContainer.updateTransform();
      }
      if (cityContainer1.x + cityContainer1.width <= 0) {
        // console.log('перемещаем город 1');
        //   console.log('before cityContainer.x ', cityContainer1.x);
        cityContainer1.x = cityContainer.x + cityContainer.width;
        //   console.log('after cityContainer.x ', cityContainer1.x);

        //   // console.log(' before remove cityContainer ', cityContainer);

        //   // const citySection = cityContainer.getChildAt(currentCitySection);
        //   // const clone = new Sprite(citySection);

        //   // cityContainer.addChildAt(clone, 14 + currentCitySection);

        //   // currentCitySection++;
        //   // console.log(' after add cityContainer ', cityContainer);
        //   // cityContainer.width += cityTextures[`city_bg_1`].width;
        //   // console.log('cityContainer.width ', cityContainer.width);
        //   // moved = true;
        //   // return;
        //   // app.stage.removeChild(cityContainer);
        //   // app.stage.addChild(cityContainer);
        //   // cityContainer.updateTransform();
      }
      cityContainer.x -= visibleWidth * backgroundVelocity.current;
      cityContainer1.x -= visibleWidth * backgroundVelocity.current;

      // groundSprite.x = ground.position.x;
      // groundSprite.y = ground.position.y;
      // groundSprite.rotation = ground.angle;

      // if ((heroRef.current?.body.position.y ?? 0) >= 550 && heroRef.current?.isJumping) {
      //   heroRef.current.isJumping = false;
      // }
      heroRef.current?.updatePosition();
      const heroBounds =
        heroRef.current?.sprite.getBounds() ??
        ({ x: 0, y: 0, width: 0, height: 0 } as unknown as PIXI.Bounds);

      const isCollision = (hero: PIXI.Bounds, object: PIXI.Bounds, objLabel: string) => {
        let { x: heroX, y: heroY, width: heroWidth, height: heroHeight } = hero;
        heroX += heroWidth / 4;
        heroY += 0;
        heroWidth -= heroWidth / 2;
        heroHeight -= objLabel !== 'puddle' ? heroHeight / 4 : 0;
        return (
          heroX < object.x + object.width &&
          heroX + heroWidth > object.x &&
          heroY < object.y + object.height &&
          heroY + heroHeight > object.y
        );
        // return (
        //   rect1.x < rect2.x + rect2.width &&
        //   rect1.x + rect1.width > rect2.x &&
        //   rect1.y < rect2.y + rect2.height &&
        //   rect1.y + rect1.height > rect2.y
        // );
      };

      // Двигаем объекты
      objectsRef.current.forEach((obj, index, arr) => {
        obj.moveLeft(objectsVelocity.current.x);
        if (obj.sprite.x < -obj.sprite.width) {
          console.log('objectsBRef remove ', obj.label);
          obj.remove();
          arr.splice(index, 1);
          console.log('Двигаем объекты - objectsBRef.current ', objectsRef.current);
          console.log('Двигаем объекты - arr ', arr);
          return;
        }

        const objectBounds = obj.sprite.getBounds();
        // Проверяем, есть ли столкновение и был ли обработан объект ранее
        if (isCollision(heroBounds, objectBounds, obj.label) && !obj.isColliding) {
          obj.isColliding = true; // Устанавливаем флаг, чтобы не обрабатывать объект повторно
          handleCollision(obj);
        }
      });

      const checkCollisions = () => {
        if (heroRef.current?.immune || isGameOver || !objectsRef.current.length) return;

        const heroBounds =
          heroRef.current?.sprite.getBounds() ??
          ({ x: 0, y: 0, width: 0, height: 0 } as unknown as PIXI.Bounds);

        for (const object of objectsRef.current) {
          const objectBounds = object.sprite.getBounds();

          if (isCollision(heroBounds, objectBounds, object.label)) {
            handleCollision(object);
            // appRef.current.stage.removeChild(object);
            // objects.current = objects.current.filter(o => o !== object);
          }
        }
      };

      // checkCollisions();

      Engine.update(engine, 1000 / 60);
      appRef.current?.renderer.render(appRef.current.stage);

      // objectsBRef.current = objectsBRef.current.filter((obj) => {
      //   obj.moveLeft(baseVelocity.current.x);
      //   obj.updatePosition();
      //   if (obj.body.position.x + obj.body.bounds.max.x < 0) {
      //     // obj.remove();
      //     // return;
      //   }
      //   return true;
      // });

      // --- определение столкновения предметов с героем ---
      // [cases, balloons, desks, puddles].forEach((container) => {
      //   const subjectType = container.type;
      //   container.particles.forEach((subject, index) => {
      //     let shouldDestroy = false;
      //     if (testForAABB(subject, hero.sprite)) {
      //       console.log('!!!collision!!! - subjectType - ', subjectType);
      //       sendMessage({ action: subjectType, value: '' });
      //
      //       shouldDestroy = true;
      //     }
      //     const { x, y, width, height } = app.stage;
      //     const left = x;
      //     const top = y;
      //     const right = x + width;
      //     const bottom = y + height;
      //     if (isOutOfViewport({ left, top, right, bottom }, subject)) {
      //       console.log('??? invisible ???');
      //       shouldDestroy = true;
      //     }
      //     if (!shouldDestroy) {
      //       subject.x -= visibleWidth * 0.005 * (currentAcceleration / 1);
      //       return;
      //     }
      //
      //     container.particles.splice(index, 1);
      //     container.removeChild(subject);
      //     subject.destroy();
      //   });
      // });
    };

    const gameLoop = () => {
      if (!isGameOver) {
        update();
        requestAnimationFrame(gameLoop);
      }
    };

    sendMessage({ action: webSocketMessages.START, value: '' });
    gameLoop();
    setTimeout(() => {
      startSpawningObjects();
    }, 2000);

    let resizeTimeout: NodeJS.Timeout;
    const resize = () => {
      clearTimeout(resizeTimeout);
      resizeTimeout = setTimeout(() => {
        appRef.current?.renderer.resize(gameContainer.clientWidth, gameContainer.clientHeight);
      }, 200); // Задержка 200 мс

      const visWidth = appRef.current?.screen.width ?? window.innerWidth;
      const visHeight = appRef.current?.screen.height ?? window.innerHeight;

      if (heroRef.current) {
        heroRef.current.setPosition(visWidth / 2, visHeight - 150);
      }

      Body.setPosition(ground, {
        x: visWidth / 2,
        y: visHeight - roadHeightRef.current / 4,
      });
      Body.setVertices(ground, [
        { x: 0, y: visHeight - roadHeightRef.current / 2 },
        { x: visWidth, y: visHeight - roadHeightRef.current / 2 },
        { x: visWidth, y: visHeight },
        { x: 0, y: visHeight },
      ]);
    };

    window.addEventListener('resize', resize);

    const cleanUp = () => {
      if (everySecTimer) clearInterval(everySecTimer);
      Howler.stop();

      if (appRef.current) {
        appRef.current?.stage?.removeChildren();
        appRef.current?.destroy(true, { children: true, texture: true });
        appRef.current = undefined;
      }
      if (engine) {
        Events.off(engine, 'collisionStart', onCollisionStart);
        World.clear(engine.world, false);
        Engine.clear(engine);
      }
      objectsRef.current = [];
      heroRef.current = undefined;
      Runner.stop(runner);
    };

    const screenWidth = appRef.current?.renderer.width;
    const screenHeight = appRef.current?.renderer.height;
    console.log('screenWidth ', screenWidth);
    console.log('screenHeight ', screenHeight);

    // app.start();

    return () => {
      window.removeEventListener('resize', resize);
      cleanUp();
    };
  };

  useEffect(() => {
    if (isGameOver || isOrientationCorrect !== true || !isAuthenticated) return;

    initGame();
  }, [isGameOver, isOrientationCorrect, isAuthenticated]);

  useEffect(() => {
    const resize = () => {
      if (!isDesktop && window.innerHeight < window.innerWidth) {
        // Портретная ориентация
        // alert('Пожалуйста, поверните устройство в портретный режим для лучшего отображения.');
        setIsOrientationCorrect(false);
      } else {
        // Альбомная ориентация
        console.log('Портретная ориентация');
        setIsOrientationCorrect(true);
      }
    };
    resize();

    const orientationChange = () => {
      const orientation = window.screen.orientation.angle;
      console.log('orientation ', orientation);

      if (!isDesktop && (orientation === 90 || orientation === 270)) {
        // Портретная ориентация
        // alert('Пожалуйста, поверните устройство в портретный режим для лучшего отображения.');
        setIsOrientationCorrect(false);
      } else {
        // Альбомная ориентация
        console.log('Портретная ориентация');
        setIsOrientationCorrect(true);
      }
    };

    window.addEventListener('resize', resize);
    window.addEventListener('orientationchange', orientationChange);

    return () => {
      window.removeEventListener('resize', resize);
      window.removeEventListener('orientationchange', orientationChange);
    };
  }, []);

  useEffect(() => {
    if (!isGameOver) return;
    console.log('useEffect - lives ', lives, isGameOver);

    Howler.stop();
    sendMessage({ action: webSocketMessages.END, value: '' });
    saveShouldReconnect(false);
    setWebSocketUrl('');
    navigate('/final');
  }, [isGameOver]);

  const toggleAudio = () => {
    setAudio((prev) => {
      saveIsAudioMuted(prev);
      Howler.mute(prev);
      return !prev;
    });
  };

  return (
    <div className="game">
      <div className="game-info">
        <div className="info-score">{score ?? 0}</div>
        <div className="info-lives">
          <LifeList activeLivesAmount={lives ?? 0} />
        </div>
        <button className="info-audio" onClick={toggleAudio}>
          <img
            className="audio-image"
            src={audio ? audioActiveSVG : audioInactiveSVG}
            alt={`${audio ? 'выключить' : 'включить'} аудио`}
          />
        </button>
      </div>
      <span style={{ position: 'absolute', top: 0, right: 5, fontSize: 15, zIndex: 6 }}>
        The WebSocket is currently {connectionStatus}
      </span>
      <div className="game-scene"></div>
      {isGameLoading && isOrientationCorrect && (
        <div className="loading">
          <Loader />
          <Text classNames={['loading-txt']} text="Игра загружается..." />
        </div>
      )}
      {isOrientationCorrect === false && (
        <Text
          classNames={['orientation']}
          text="
          Пожалуйста, поверните устройство в портретный режим для лучшего отображения."
        />
      )}
    </div>
  );
};

export default Game;
