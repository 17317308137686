import { TwoSidesLayout } from 'pages';
import { FC } from 'react';
import Rules from './Rules';
import { SubjectsPopup } from 'shared/ui/SubjectsPopup/SubjectsPopup';
import './../index.scss';

const RulesPage: FC = () => {
  return (
    <TwoSidesLayout rightSideChilden={<SubjectsPopup />}>
      <Rules />
    </TwoSidesLayout>
  );
};

export default RulesPage;
