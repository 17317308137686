import React, { InputHTMLAttributes, forwardRef } from 'react';
import './Input.scss';

interface InputProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * @description Включает состояние поле - ошибка
   */
  isDanger?: boolean;
  /**
   * @description Сообщение ошибки;
   * для отображение параметр isDanger должен быть true
   */
  dangerText?: string;
}

export const Input = forwardRef<HTMLInputElement, InputProps>(
  ({ dangerText = 'Неверно задано поле', ...props }, ref) => {
    const { isDanger, value, className = '', onChange, ...rest } = props;

    return (
      <div className="input-box">
        <input
          ref={ref}
          className={['input-field', isDanger ? 'danger' : '', className].join(' ')}
          type="text"
          value={value} // Ensure value is set from props
          onChange={onChange} // Ensure onChange is properly wired
          {...rest}
        />
        {isDanger && <span className="danger-text">{dangerText}</span>}
      </div>
    );
  }
);
