const KEY = 'aeroflot';
export const loadState = () => {
  try {
    const serializedState = localStorage.getItem(KEY);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveState = async (state: any) => {
  try {
    const serializedState = JSON.stringify(state);
    localStorage.setItem(KEY, serializedState);
  } catch (e) {
    // Ignore
  }
};

export const loadContext = (key: string) => {
  try {
    const serializedState = sessionStorage.getItem(`${KEY}-${key}`);
    if (!serializedState) return undefined;
    return JSON.parse(serializedState);
  } catch (e) {
    return undefined;
  }
};

export const saveContext = async (key: string, context: any) => {
  try {
    const serializedState = JSON.stringify(context);
    sessionStorage.setItem(`${KEY}-${key}`, serializedState);
  } catch (e) {
    // Ignore
  }
};

export const removeContext = async (key: string) => {
  try {
    sessionStorage.removeItem(`${KEY}-${key}`);
  } catch (e) {
    // Ignore
  }
};

export const removeContexts = async () => {
  for (let i = 0; i < sessionStorage.length; i++) {
    const key = sessionStorage.key(i);

    // Проверяем, начинается ли ключ с указанного префикса
    if (key && key.startsWith(KEY)) {
      try {
        sessionStorage.removeItem(key); // Удаляем элемент с таким ключом
        i--; // Уменьшаем индекс, так как sessionStorage.length уменьшился после удаления
      } catch (e) {
        // Ignore
      }
    }
  }
};
