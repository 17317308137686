import { FC, PropsWithChildren, ReactNode, RefObject } from 'react';
import { createPortal } from 'react-dom';
import { closeSVG } from '../../resourcesBase64/images/icons';
import './Modal.scss';

interface ModalProps {
  isOpen: boolean;
  onClose: () => void;
  container: HTMLElement | RefObject<HTMLDivElement> | null;
  children: ReactNode;
  cls: string;
}
const Modal: FC<ModalProps & PropsWithChildren> = ({
  isOpen,
  onClose,
  container,
  cls,
  children,
}: ModalProps) => {
  if (!isOpen) return null;

  return createPortal(
    <div className={`modal ${cls}`}>
      <div className="modal-container">
        <div className="modal-body">{children}</div>
        <button className="btn-close" onClick={() => onClose()}>
          <img src={closeSVG} alt="закрыть" />
        </button>
      </div>
    </div>,
    container as HTMLElement
  );
};

export default Modal;
