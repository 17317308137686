import { InputHTMLAttributes, forwardRef } from 'react';
import { Text } from '../Text/Text';
import './Checkbox.scss';

interface CheckboxProps extends InputHTMLAttributes<HTMLInputElement> {
  /**
   * @description Видимый текст рядом с чекбоксом
   */
  text?: string;
  /**
   * @description Выбран ли чек-бокс
   */
  checked?: boolean;
  /**
   * @description Метод изменения чек-бокса
   */
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
}

export const Checkbox = forwardRef<HTMLInputElement, CheckboxProps>(({ text, ...props }, ref) => (
  <label className="checkbox-container">
    <input type="checkbox" ref={ref} {...props} />
    <div className="custom-checkbox"></div>
    <Text size="small" text={text} />
  </label>
));
