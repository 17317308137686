import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import { GetUuidResponse } from 'shared/redux/services/uuidApi';
import { loadState } from '../browser-storage';

export type ITokensState = {
  refresh_token: string | null;
  access_token: string | null;
  user_created?: boolean;
};

export type IUserState = {
  email: string | null;
  nickname: string | null;
};

export type IAuthState = {
  uuid: string | null;
  isAuthenticated: boolean;
  isWebSocketConnected: boolean;
  webSocketStatus: string | null;
} & ITokensState &
  IUserState;

//Defining initial state
const INITIAL_STATE = {
  refresh_token: null,
  access_token: null,
  user_created: false,
  uuid: null,
  email: null,
  nickname: null,
  isAuthenticated: false,
  isWebSocketConnected: false,
  webSocketStatus: null,
} as IAuthState;

const persistedState = loadState();

//defining slice. It will set credentials after successfull login
//It will logout user from application
const authSlice = createSlice({
  name: 'auth',
  initialState: { ...INITIAL_STATE, ...persistedState },
  reducers: {
    setTokens: (state: IAuthState, action: PayloadAction<ITokensState>) => {
      const { refresh_token, access_token, user_created } = action.payload;
      state.refresh_token = refresh_token;
      state.access_token = access_token;
      state.user_created = user_created;
      state.isAuthenticated = true;
    },
    setAccessToken: (state: IAuthState, action: PayloadAction<Record<'access_token', string>>) => {
      const { access_token } = action.payload;
      state.access_token = access_token;
      state.isAuthenticated = true;
    },
    setUser: (state: IAuthState, action: PayloadAction<IUserState>) => {
      const { email, nickname } = action.payload;
      state.email = email;
      state.nickname = nickname;
    },
    setNickname: (state: IAuthState, action: PayloadAction<Record<'nickname', string>>) => {
      const { nickname } = action.payload;
      state.nickname = nickname;
    },
    setUuid: (state: IAuthState, action: PayloadAction<GetUuidResponse>) => {
      const { uuid } = action.payload;
      state.uuid = uuid;
    },
    setIsWebSocketConnected: (
      state: IAuthState,
      action: PayloadAction<Record<'isWs', boolean>>
    ) => {
      const { isWs } = action.payload;
      state.isWebSocketConnected = isWs;
    },
    setWebSocketStatus: (state: IAuthState, action: PayloadAction<Record<'ws', string | null>>) => {
      const { ws } = action.payload;
      state.webSocketStatus = ws;
    },
    logOut: (state: IAuthState) => {
      state.refresh_token = null;
      state.access_token = null;
      state.uuid = null;
      state.email = null;
      state.nickname = null;
      state.isAuthenticated = false;
      state.isWebSocketConnected = false;
      state.webSocketStatus = null;
    },
  },
  // extraReducers: (builder) => {
  //   builder.addMatcher(authApi.endpoints.login.matchFulfilled, (state, { payload }) => {
  //     state.refresh_token = payload.refresh;
  //     state.access_token = payload.access;
  //   });
  // },
});

//exporting actions
export const {
  setTokens,
  setAccessToken,
  setUuid,
  setUser,
  setNickname,
  setIsWebSocketConnected,
  setWebSocketStatus,
  logOut,
} = authSlice.actions;

//exporting reduer
export default authSlice.reducer;

//deriving data
export const selectCurrentRefreshToken = (state: RootState) => state!.auth.refresh_token;
export const selectCurrentAccessToken = (state: RootState) => state!.auth.access_token;

export const selectCurrentIsAuthenticated = (state: RootState) => state!.auth.isAuthenticated;

export const selectCurrentUuid = (state: RootState) => state!.auth.uuid;

export const selectCurrentNickname = (state: RootState) => state!.auth.nickname;

export const selectCurrentIsWebSocketConnected = (state: RootState) =>
  state!.auth.isWebSocketConnected;

export const selectCurrentWebSocketStatus = (state: RootState) => state!.auth.webSocketStatus;
